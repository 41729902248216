// opacity animation
.opacity-enter {
  opacity: 0.4;
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  transition: opacity $transition-timing ease-in;
}

.opacity-leave {
  opacity: 1;
}

.opacity-leave.opacity-leave-active {
  opacity: 0.4;
  transition: opacity $transition-timing ease-in;
}

// slide in animation (@used-in phones, state output, stats, system status)
.container-enter {
  opacity: 0.4;
  transform: translateY(-20px);
}

.container-enter.container-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all $transition-timing*2 ease-in-out;
}

.container-leave {
  opacity: 1;
  transform: translateY(0);
}

.container-leave.container-leave-active {
  opacity: 0;
  transform: scale(0.4);
  transition: all $transition-timing*2 ease-in-out;
  animation: $transition-timing*2 0.4s width;
}

// scale anim
.scale-out-enter {
  opacity: 0;
  transform: scaleY(0);
  height: 0;
  transition: all $transition-timing ease-in-out;
}

.scale-out-enter.scale-out-enter-active {
  opacity: 1;
  height: 30px;
  transform: scaleY(1);
}
// due the performance degrdation we skip further animations..
.scale-out-leave, .scale-out-leave.scale-out-leave-active {
  opacity: 0;
  transform: scaleY(0);
  height: 0;
  transition: all $transition-timing cubic-bezier(0.250, 0.250, 0.750, 0.750);
}


// slide out anim
.slide-out-enter, .slide-out-leave {
  opacity: 0;
  transform: translateY(-3vh);
  // transform: perspective(500px) translate3d(0, -3vh, 300px);
  background: #fff;
  height: 0;
  transition: all $transition-timing*2 ease-out;
}

.slide-out-enter.slide-out-enter-active {
  opacity: 1;
  transform: translateY(0);
}

// has to be same as @keyframes fade-in! minus scale.
.fadein-enter, .fadein-appear {
  transform: translateY($gutter/3);
	opacity: 0;
  //@extend .anim;
  transition: all 0.4s $transition-timing-function;
  &.fadein-enter-active, &.fadein-appear-active  {
    opacity: 1;
    transform: translateY(0);
    //margin-top: 0;
  }
}