.adaptive-grid {
	display: flex;
	flex-flow: row wrap; // this creates gap on some pages on mobile.
	// @extend .bg-light;
	&:not(.compact) {
		> *:nth-of-type(-n+2) {
			padding-bottom: $gutter;
		}
	}
	&.compact {
		@include breakpoint_max($sm) {
			padding: 0 0;
		}
		ul li:last-of-type {
			margin-bottom: 0;
		}
	}
	@include breakpoint_max($md) {
		.background-chart-blend-above {
			border-bottom: none;
			+ div {
				background: $color-chart-background;
				border-bottom: 1px solid $color-transparent-white-1;
			}
		}
	}
	> * {
		flex: 1 0 50%;
		@include breakpoint_max($xl) {
			flex: 0 0 100%;
			padding: $gutter/2 $gutter;
			border-bottom: 1px solid $color-transparent-white-1;
			// border-bottom: 1px solid $color-transparent-black-9;
			// border-bottom: 1px solid $color-transparent-black-1;
			&:nth-last-of-type(-n+1) {
				border-bottom-color: transparent;
			}
		}
		// nested adaptive grid.
		@include breakpoint_max($xl) {
			.adaptive-grid > * {
				flex: 0 0 100%;
			}
		}
		&:nth-last-of-type(-n+2) {
			margin-bottom: 0;
			// border-bottom-color: transparent;
		}
		&.border-left {
			@include breakpoint_min($xl) {
				flex-basis: 40%;
				border-left: 1px solid $color-transparent-white-1;
				// border-left: 1px solid $color-transparent-black-9;
				//border-left: 1px solid $color-transparent-black-1;
				padding-left: $gutter;
				margin-left: $gutter;
				// background: $color-transparent-black-7;
				// background: $color-transparent-black-5; // @full-page
			}
		}
		&.border-right {
			@include breakpoint_min($xl) {
				flex-basis: 40%;
				border-right: 1px solid $color-transparent-white-1;
				padding-right: $gutter;
				// margin-right: $gutter;
				padding-left: $gutter;
				// background: $color-transparent-black-7; // @full-page
			}
		}
	}
}

// @used PhoneEditAttributes
.adaptive-grid.natural-grid > * {
	@include breakpoint_min(1px) {
		padding-bottom: 0;
	}
	@include breakpoint_max($md) {
		padding: 0;
		border: 0;
	}
}