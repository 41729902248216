.filter {
    font-size: 1rem;
    list-style: none;
    padding: 0;
    // margin: $gutter/2 0;
    //border: 1px solid $color-muted;
    //border-radius: $border-radius;
    // overflow: hidden;
    // color: $color-muted;
    color: $color-white;
    display:inline-block;
    font-size: 0.8rem;
    text-transform: uppercase;
    &.filter-dark {
        color: $color-primary;
    }
    li {
        display: inline-block;
        padding: $gutter/3;
        padding: $gutter/8;
        opacity: 0.2;
        @extend .anim;
        &:not(.head) {
            // background: $color-neutral;
            // color: #fff;
            @extend .cursor-pointer;
        }
        &.active {
            // background:$color-transparent-white-3;
            // border-bottom: 1px solid $color-muted;
            // color: $color-secondary;
            opacity: 1;
        }
        &:hover:not(.active):not(.head) {
            // background:$color-transparent-white-1;
            opacity: 1;
        }
        i {
            padding-right: $gutter/6;
        }
    }
}