.table {
    width: 100%;
    display: table;
    .table-row {
        display: table-row;
    }
    .table-cell {
        display: table-cell;
        padding: $gutter/4;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }
}