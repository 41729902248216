.btn {
    $border-radius: $border-radius/2;
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1em;
    padding: $gutter/2 $gutter/2.5;
    border-radius: $border-radius;
    // color: $color-white;
    color: $color-real-white;
    background: transparent;
    border: 1px solid transparent;
    outline: none;
    @extend .cursor-pointer;
    @extend .font-distinct;
    @extend .font-weight-300;
    @extend .anim;
    //@extend .text-uppercase;
    //@extend .box-shadow;
    // border-style: inset;
    + .btn {
        margin-left: $gutter/2;
    }
    &:disabled {
        opacity: 0.4;
        @extend .cursor-disabled;
    }
    &:hover:not(.btn-choose) {
        transform: scale(1.05);
    }
    &:active:not(.btn-choose)  {
        transform: scale(0.95);
    }
    i {
        padding-right: $gutter/4;
        vertical-align: bottom;
        &.middle {
            vertical-align: middle;
        }
        // line-height: 1.1em;
    }
    span {
        font-weight: 500;
        @extend .text-uppercase;
    }
    &.btn-slim {
        padding: $gutter/3 $gutter/2.5;
    }
    &.btn-lg {
        padding: $gutter/1.5;
        font-size: 1.3rem;
        line-height: 1.3em;
    }
    &.btn-primary {
        background: $color-primary;
         &:hover, &:focus {
            background: darken($color-primary, 9%);
        }
    }
    &.btn-secondary {
        background: $color-secondary;
         &:hover, &:focus {
            background: darken($color-secondary, 9%);
        }
    }

    // @todo mixins outline
    &.btn-border, &.btn-outline-white {
        border-color: $color-real-white;
        &:hover, &:focus {
            background: $color-real-white;
            color: $color-text;
            text-shadow: none;
        }
    }
    &.btn-outline-white-transparent {
        $color: rgba(0, 0, 0, 0.3);
        border-color: $color;
        color: $color;
        &:hover, &:focus {
            background: $color;
            // color: $color;
        }
    }
    &.btn-outline-primary {
        border-color: $color-primary;
        color: $color-primary;
        &:hover, &:focus {
            background: $color-primary;
            color: $color-white;
        }
    }
    &.btn-outline-secondary {
        border-color: $color-secondary;
        color: $color-secondary;
        &:hover, &:focus {
            background: $color-secondary;
            color: #fff;
        }
    }
    &.btn-outline-error {
        border-color: $color-error;
        color: $color-error;
        &:hover, &:focus {
            background: $color-error;
            color: #fff;
        }
    }
    &.btn-outline-success {
        border-color: $color-succes;
        color: $color-succes;
        &:hover, &:focus {
            background: $color-succes;
            color: #fff;
        }
    }
    &.btn-outline-muted {
        border-color: $color-muted;
        color: $color-muted;
        &:hover, &:focus {
            background: $color-muted;
            color: #fff;
        }
    }
    &.btn-outline-producthunt {
        border-color: $color-producthunt;
        color: $color-producthunt;
        &:hover, &:focus {
            background: $color-producthunt;
            color: #fff;
        }
    }
    &.btn-muted {
        background: $color-muted;
        border-color: darken($color-muted, 9%);
        &:hover, &:focus {
            background: darken($color-muted, 9%);
        }
    }   
    &.btn-white {
        background: $color-real-white;
        color: $color-text;
        border-color: darken($color-real-white, 9%);
        &:hover, &:focus {
            background: darken($color-real-white, 9%);
        }
    }   
    &.btn-success {
        background: $color-succes;
        border-color: darken($color-succes, 9%);
        &:hover, &:focus {
            background: darken($color-succes, 9%);
        }
    }
    &.btn-error {
        background: $color-error;
        border-color: darken($color-error, 9%);
        &:hover, &:focus {
            background: darken($color-error, 9%);
        }
    }
    &.btn-facebook {
        background: $color-facebook;
        border-color: darken($color-facebook, 9%);
        &:hover, &:focus {
            background: darken($color-facebook, 9%);
        }
    }
    &.btn-choose {
        border-radius: 0;
        min-width: 68px;
        margin: 0;
        // background: $color-muted;
        // background: $color-transparent-black-3;
        background: darken($color-light, 0); 
        background: #fff;
        border-radius: $border-radius 0 0 $border-radius;
        margin-bottom: $gutter/2;
        &:not(.btn-selected-success):not(.btn-selected-error):not(.btn-transparent) {
            // color: darken($color-light, 20);/** @inputoverlay **/
            // border: 1px solid darken($color-light, 5%); /** @inputoverlay **/
            color: $color-muted;/** @inputoverlay **/
            border: 1px solid $color-muted;
        }
        &:not(.btn-lg) {
            // font-size: 1.1rem;
            // line-height: 1.2em;
            font-size: 1rem;
            line-height: 1.1em;
        }
        &.wide {
            width: 50%;
        }
        &.btn-transparent {
            background: transparent;
            border-color: $color-transparent-white-3;
            &:hover, &:focus {
                border-color: $color-transparent-white-5;
            }
        }
        + .btn-choose {
            border-left-color: transparent;
            border-radius: 0 $border-radius $border-radius 0;
        }
        &.btn-selected-success {
            background: $color-succes;
            &:hover, &:focus {
                background: darken($color-succes, 9%);
            }
        }
        &.btn-selected-error {
            background: $color-error;
             &:hover, &:focus {
                background: darken($color-error, 9%);
            }
        }
    }
    &.btn-padding {
        padding-left: $gutter*1.5 !important;
        padding-right: $gutter*1.5 !important;
    }
}

.btn-link {
    font-size: 0.9rem;
    background: none;
    border: none;
    outline: none;
    @extend .stronger;
    @extend .cursor-pointer;
    @extend .anim;
    @extend .text-uppercase;
    + .btn-link {
        margin-left: $gutter/2;
    }
    &:active {
        transform: scale(0.9);
    }
    &.btn-link-primary {
        color: $color-primary;
    }
    &.btn-link-success {
        color: $color-succes;
    }
    &.btn-link-error {
        color: $color-error;
    }
    &.btn-link-muted {
        color: $color-muted;
    }
    &[disabled] {
        opacity: 0.4;
        @extend .cursor-disabled;
    }
}

.btn-no-style, .btn-contextual {
    background: none;
    border: none;
    outline: none; 
    margin: 0;
    padding: 0;
    font-size: 1em;
    &.btn-contextual {
        border-bottom: 1px dotted currentColor;
    }
    + .btn-contextual {
        margin-left: $gutter/2;
    }
}


// put some gap in some forms
form div + button:not(.btn-link):last-of-type {
    margin-top: $gutter;
}

// .transparent .btn-primary {
//     color: $color-white;
// }