// pallete: https://colorhunt.co/palette/137864
// 113f67 34699a 408ab4 65c6c4
$cover-path: '~assets/img/blue5/cover.jpg';
$cover-back-path: '~assets/img/blue5/cover.jpg';

$color-chart-background: rgba(255, 255, 255, 0.03);

// $color-background-base: #3831a6; // cool navy (mobilpay)
// $color-background-base: #247e94; // cool cyan
$color-background-base: #2d488a; // that blue
// $color-background-base: #2d588a; // nice blue
// $color-background-base: #465597; // cool navy nice
$color-background-base: #295a9b; // 
// $color-background-base: #555499; //  bootstrap navy
// $color-background-base: #1d426e; // 
// $color-background-base: rgb(74, 87, 145);; // nice navy

$color-background-base-light: lighten($color-background-base, 60%); // 4 that blue
$color-background-base-light: lighten($color-background-base, 58%); 

$color-black: #333;
$color-black: #555;

$color-light: #f1f1f1;
$color-light: $color-background-base-light;
$color-muted: #949494;
// $color-muted: darken($color-background-base-light, 30%);

$color-neutral: #f0f0f0;
// $color-neutral: lighten($color-background-base, 50%);
$color-white: rgba(255, 255, 255, 0.65); // #cdb2d0
$color-white: rgba(255, 255, 255, 0.8); // #cdb2d0
$color-real-white: #fff;

$color-accent: lime;
$color-accent: lighten($color-background-base, 30%);
$color-primary: darken($color-background-base, 10%); 
$color-secondary: lighten($color-background-base, 20%); // nice purple: #452b45
$color-section: $color-primary;

$color-primary-transparent: rgba($color-primary, 0.9);

$color-succes: #5c8d89;
// $color-succes: #269393;
$color-error: #d34848;
// $color-error: #ce472f;

$bg-color-primary: lighten($color-primary, 40);

// .parallax {
//     filter: blur(20px);
// }


// $main-bg: darken($color-background-base, 10%);
$parallax-bg: linear-gradient(to top, darken($color-primary, 25%)  0, $color-primary 100%); // @deprecated

$color-overlay:  linear-gradient(to bottom, $color-transparent-black-9 40%, transparent 100%);;
$color-overlay: linear-gradient(to bottom, darken($color-background-base, 35%) 40%, $color-background-base 100%);;
$color-overlay: darken($color-background-base, 15%);
$color-overlay: darken($color-background-base, 5%);
// $color-overlay: #37368a;

$color-secondary-transparent: #fff;
$color-secondary-transparent: $color-white; // was primary.

$header-background: darken($color-background-base, 10%);
$header-background: $color-background-base;


$main-bg: $color-background-base;
