.search-fullscreen-mode .app {
    transform: translateY(-$gutter*4);
    transition-delay: 0;
    @include breakpoint_max($sm) {
        .tabpane > div {
            padding-left: 0;
            padding-right: 0;
            .phones {
                padding-left: $gutter/2;
                padding-right: $gutter/2;
                overflow: hidden;
            }
        }
    }
}
.new-phone-container {
    // z-index: 2;
    // z-index: 101;
    position: relative;
}
.new-phone {
    $img-width: 90px;
    $border-color-outline: rgba($color-primary, .4);
    $color-overlay: $color-primary;
    // transform: perspective(800px) rotateX(-4deg);
    // max-width: 800px;
    margin:auto;
    background: #fff;
    padding: 0;
    padding-bottom: $gutter/2;
    padding-top: $gutter/3;
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: 0 6px 12px -8px $color-transparent-black-2;
    
    // @extend .box-shadow;
    @include breakpoint_max($sm) {
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
    }
    // &.selected {
        //     background: linear-gradient(to bottom, $color-real-white 200px, $color-light);
    // }
    &.selected:not(.exists) {
        margin-bottom: $gutter*2;
        position: relative !important;
        margin-bottom: -$gutter*3.35;
        padding-bottom: 0;
    }
    &:not(.selectedFreakin) {
        top: -$gutter*3.3;
        position: absolute;
        left: 0;
        z-index: 0;
        width: 100%;
        @include breakpoint_min($md) {
            top: -80px;
        }
    }
    .phone-attributes {
        margin: 0;
        margin-top: $gutter/2;
        padding: $gutter 0;
        &.error {
            background: lighten($color-alert, 40%);
        }
        color: $color-text;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // background: $color-transparent-black-1;
        background: $color-light;

        // @quickfix
        @include breakpoint_max($md) {
            background: linear-gradient(to bottom, $color-light, #fff);
            input {
                border-color: $color-muted;
        
                &:focus {
                    border-bottom-color: darken($color-muted, 15%);
                }
            }
        }
        
        // box-shadow: 0 -6px 12px -8px $color-transparent-black-2;
        .img-container {
            order: 2;
            &.img-simple {
                flex-basis: $img-width + 0;
            }
            .phone {
                width: 190px;
                float: none;
                margin: auto;
            }
            // align-self: flex-end;
        }
        .edit-basic {
            order: 1;
            flex-basis: 60%;
            // max-width: 200px;
        }
        .edit-color {
            order: 3;
            // width: 100%;
            flex-basis: 100%;
            padding-top: $gutter;
        }
        @include breakpoint_min($lg) {
            padding: $gutter $gutter;
            // padding-right: 0;
            // padding-bottom: 0;
            .img-container {
                order: 1;
                // align-self: flex-end;
                // text-align: center;
                align-self: center;
                margin-top: 0;
            }
            .edit-basic, .edit-color {
                max-width: 410px;
                background: #fff;
                padding: $gutter/2 0;
            }
            .edit-basic {
                order: 2;
                border-radius: $border-radius 0 0 $border-radius;
            }
            .edit-color {
                order: 3;
                flex-grow: 0;
                margin-left: auto;
                border-radius: 0 $border-radius  $border-radius 0;
            }
            > div {
                flex-basis: auto !important;
            }
        }
        > div {
            flex: auto;
        }
        .edit-color, .edit-basic {
            p, label {
                // @extend .text-md;
                @extend .text-black;
                @extend .strong;
                // @extend .text-uppercase;
            }
        }
        button.btn-choose {
            width: 45%;
        }
    }
    // search container
    > div:nth-child(1) {
        // flex-grow: 1;
        position: relative;
        // z-index: 1;
        padding-bottom: $gutter*2.5;
        p {
            display: block;
            padding-left: $gutter;
        }
    }
    > p {
        bottom: -$gutter/6;
        left: $gutter*2.5;
        font-size: 0.8rem;
        @extend .text-uppercase;
        @extend .strong;
        @extend .text-success;
    }
    .img-container.img-simple {
        position: relative;
        @extend .text-center;
        span {
            display: inline-flex;
            @extend .overlay-bg-white-fix;
        }
        + .adaptive-grid {
            width: calc(100% - #{$img-width + $gutter*4});
        }
        img {
            // height: 148px;
            height: 119px;
            width: $img-width;
            // margin-bottom: $gutter/4;
            @include breakpoint_min($lg) {
                width: 120px;
                height: 158px;
            }
            // mix-blend-mode: multiply;
        }
    }   

    .control {
        @include breakpoint_min($lg) {
            padding: 0 $gutter/2;
        }
    }
}

.empty-everything-new-phone {
    pointer-events: all !important;
    &.letsgo {
        transform: rotateZ(-45deg);
        color: $color-alert;
    }
}