.color-picker {
    b {
        user-select: none;
        // color: $color-white;
    }
    .color-summary {
        b, span {
            vertical-align: middle;
        }
    }
    &.collapsed {
        position: relative;
        // trick for preventing hover when moving mouse in line with toggles.
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 70%;
        }
        .colors {
            pointer-events: none;
            opacity: 0;
            position: absolute;
            right: -$gutter/4;
            left: -$gutter/4;
            bottom: -$gutter/4; 
            overflow: hidden;
            z-index: 1;
            @extend .border-radius;
            @extend .anim;
            @include transition-translate(Y, $distance: $gutter/2);
        }
        .color-summary {
            position: relative;
            &:hover {
                .colors {
                    background: $color-light;
                    pointer-events: auto;
                    padding-top: $gutter/6;
                    padding-bottom: $gutter*1.5;
                    opacity: 1;
                    @include transition-translate(Y, true);
                }
                > span, > b {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
    &.expanded {
        .color {
            // border: 1px solid darken($color-light, 5%); /** @inputoverlay **/
            border: 1px solid $color-neutral; /** @inputoverlay **/
            @include breakpoint_max($sm) {
                margin: $gutter/8 $gutter/6;
            }
        }
        .colors {
            margin-top: $gutter/2;
            margin-left: -$gutter/4; // we correct the gap of first cols
            // margin-left: -$gutter/6; // we correct the gap of first cols
        }
        .color-summary {
            > span, > b {
                display: none;
            }
            // max-width: 500px;
            // @extend .border-light, .padding-sm, .bg-light, .border-radius;
            // @include breakpoint_min($md) {
            //     max-width: 364px;
            // }
        }
    }
    // span {
    //     vertical-align: middle;
    // }
    .color {
        display: inline-block;
        width: $gutter*1.2;
        height: $gutter*1.2;
        margin: $gutter/8 $gutter/4;
        border: 1px solid transparent;
        outline: none;
        @extend .border-radius;
        @extend .anim;
        @extend .action;
        &:hover, &.active, &:focus {
            transform: scale(0.9);
            border-color: #fff;
            box-shadow: inset 0 0 5px $color-transparent-black-1, 0 0 5px $color-transparent-black-3;
        }
    }
}

// @via Phones Edit
.edit {
   .colors {
        @extend .text-left;
   }
   .color {
        width: $gutter*0.88;
        height: $gutter*0.88;
    }
    .color-summary > .color {
        border-radius: 50%;
        width: $gutter*0.5;
        height: $gutter*0.5;
    }
    .color-picker {
        margin-top: $gutter/4;
    }
}