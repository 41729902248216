.overlay-screen {
    z-index: 102;
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1rem;
    line-height: 1.5em;
    text-shadow: none;
    font-weight: 300;
    background: $color-transparent-black-7;
    // @extend .anim;
    @extend .text-left;
    @extend .font-distinct;
    &.will-have-blur {
        background: #0007;
    }
    &.has-blur {
        backdrop-filter: blur(4px);
        background: #0007;
    }
    &.hidden {
        background: transparent;
    }
    &.warning {
        > div {
            // background: lighten($color-alert, 20%);
            background: linear-gradient(-45deg, lighten($color-alert, 30%), lighten($color-alert, 20%));

        }
    }
    > div {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        margin: auto;
        max-width: 800px;
        // max-width: 40em;
        min-height: 200px;
        background: linear-gradient(-45deg, lighten($color-background-base, 30%), lighten($color-background-base, 0%));
        @include breakpoint_min($md) {
            top: $gutter*2;
            bottom: $gutter*2;
            left: $gutter;
            right: $gutter;
            border-radius: $border-radius;
            height: 550px;

            // border: 1px solid #fff;
            box-shadow: 0 0 $gutter $color-transparent-black-5;
        }
    }
    .overlay-content-wrapper {
        position: relative;
        // position: absolute;
        background: #fff;
        color: $color-text;
        padding: $gutter;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        @include breakpoint_max($sm) {
            height: 100%;
            margin-top: $gutter/2;
            textarea {
                height: 35vh;
            }
        }
        @include breakpoint_min($md) {
            position: absolute;
            top: $gutter*2.5;
            bottom: $gutter/2;
            left: $gutter/3;
            right: $gutter/3;
            // overflow: auto;
            border-radius: $border-radius;
        }

        .overlay-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            // @bug it seems overflow scroll doesn't work with justify-content flex end.
            // justify-content: flex-end;
            // thks to https://stackoverflow.com/a/37515194/1894856
            flex-flow: column nowrap;
            overflow: auto;
            > :first-child {
                margin-top: auto
            }
        }
    }
    h1 {
        z-index: 100;
        position: absolute;
        width: 100%;
        height: $gutter*3;
        top: 0;
        left: 0;
        color: #fff !important;
        padding: $gutter/2 $gutter !important;
        font-size: $gutter*1;
        line-height: $gutter*1.5;
        @include breakpoint_max($md) {
            display: none;
        }
        i {
            font-style: normal;
            text-shadow: none;
            @extend .pull-right;
            @extend .cursor-pointer;
            @extend .text-sm;
        }
    }
}