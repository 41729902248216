.tabpane {
    $base_dimension: 0.9rem;
    $padding_inner: $gutter/1.5;
    padding: 0;
    font-size: 1rem;
    // background: $color-neutral;
    // why disable? because it makes everything darker. and we for sure want the cool blurred overlay to be seen.
    // background: $color-transparent-black-3;
    // background: rgba($color-primary, .3);
    // side text

    .tabs {
        display: block;
        // position: sticky;
        // top: $gutter*2.9;
        // z-index: 1;
        // background: rgba($color-overlay, 0.95);
        > ul {
            padding-top: 4px !important; // @animation-gap
        }

        // tabs
        > ul {
            padding: 0 $gutter;
            padding-top: 4px; // @animation-gap
            list-style: none;
            li {
                position: relative;
                display: inline-block;
                padding: 0;
                color: #fff;
                // border-top: 1px solid transparent;
                @extend .cursor-pointer;
                // @extend .anim;
                @extend .tap-highlight-transparent;
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 5px;
                    left: 50%;
                    transform: translateX(-50%);
                    top: $gutter/4;
                    top: 0;
                    // background: #fff;
                    opacity: 0;
                    border-radius: $border-radius;
                    @extend .anim;
                }
                &:hover:before, &.active:before {
                    opacity: 1;
                    // background: $color-primary; 
                    // background: $color-secondary; // @aero
                    background: $color-accent; // @aero
                    //width: 20%;
                    width: $gutter*1.5;
                }
                // &:first-of-type {
                //    a {
                //         @include breakpoint_min($md) {
                //             padding-left: 0;
                //         }
                //    }
                // }
                &.active, &:hover {
                    a {
                            opacity: 1;
                    }
                }
                // &:first-of-type a {
                //     padding-left: 0;
                // }
                a {
                    color: $color-white; // $color-real-white
                    // color: $color-secondary;
                    padding: $padding_inner;
                    display: inline-block;
                    vertical-align: middle;
                    opacity: 0.8;
                    @extend .anim;
                }
                span {
                    $base_dimension: 2.5em;
                    display: inline-block;
                    vertical-align: middle;
                    // we remove the circle, we make it flatter.
                    // border-radius: 50%;
                    // color: $color-section;
                    // background: $color-white;
                    // color: $color-primary; // @aero
                    // color: $color-secondary; // @aero
                    color: $color-accent;
                    // @each $property in width height line-height {
                    //     #{$property}: $base_dimension;
                    // }
                    // fixing centering @browser bug?
                    // line-height: $base_dimension+0.1;
                    padding-right: 1px;
                    // end fix
                    margin-left: $gutter/4;
                    font-size: 0.6em;
                    // new changes, for flat sakes
                    // font-size: 1.4em;
                    // line-height: 1em;
                    font-size: 1.3em;
                    line-height: 1.1em;
                    margin-left: $gutter/3;
                    vertical-align: top;
                    @extend .text-center;
                    // @include breakpoint_max($sm) {
                    //     line-height: $base_dimension+0.3;
                    // }
                }
            }
        }
        > ul, > p {
            // @experiment
            // position: -webkit-sticky;
            // position: sticky;
            // top: 0;
            // top: 10vh;
            // z-index: 2;
            // background: #111;
            overflow: hidden;
            overflow-x: auto;
            white-space: nowrap;
            font-size: 1em;
            // @extend .scrollbar;
            @include breakpoint_max($sm) {
                width: 100%;
                font-size: 0.9em;
                padding-left: 0;
            }
            @extend .font-distinct;
            @extend .font-weight-300;
        }
        > p {
            padding: $padding_inner $gutter;
            margin: 0;
            color: $color-transparent-white-5;
            &.pull-left {
                padding-right: 0;
                + ul {
                    padding-left: 0;
                }
            }
            a {
                color: $color-transparent-white-5;
                @extend .anim;
                &:hover {
                    color: $color-white; // $color-real-white
                }
            }
            i {
                padding-left: $gutter;
                padding-right: 4px;
                // @include neon($color-section, $steps: 2, $intensity: 5px);
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
    // content
    > div {
        position: relative;
        background: #fff;
        color: $color-text;
        padding: $gutter;
        min-height: 280px + $gutter*2; // as a skeleton we use 1 row of phones
        min-height: 200px; // or user-cards.
        @include breakpoint_max($sm) {
            padding: $gutter/2;
        }
        // @include breakpoint_min($xxl) {
        //     padding-top: $gutter;
        //     padding-bottom: $gutter;
        // }
        &.transparent {
            background: transparent;
            // background: rgba(0, 0, 0, 0.5) !important; // @looks better. or not. lol
            padding: 0;
        }
    }
}


.tabpane .tabs.alternate > ul {
    a {
        @extend .strong;
        &::before {
            content: '';
            position: absolute;
            background: #fff;
            transform: scale(0);
            transform-origin: center;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            @extend .border-radius;
        }
    }

    li.active, li:hover {
        &:before {
            content: none;
            background: none;
        }
        a {
            &::before {
                $base-metrics: 2px;
                top: $base-metrics;
                bottom: $base-metrics;
                left: $base-metrics*4;
                right: $base-metrics*4;
        
                top: $base-metrics*4;
                bottom: $base-metrics*4;
        
                opacity: 1;
                transform: scaleY(1);
                // transition: none;
                @extend .anim;
            }
            color: $color-primary;
        }
    }
}