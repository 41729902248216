.notice {
    padding: $gutter;
    font-size: 1.5rem;
    // background: $color-transparent-black-7;
    line-height: 1.5em;
    @extend .text-center;
    @extend .font-distinct;
    @extend .bg-transparent-light;
    .btn {
        margin-left: $gutter;
    }
}