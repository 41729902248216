.color-spectrum {
    // display: none !important;
    $base-metric: $gutter*3.2;
    position: absolute;
    z-index: 3;
    display: block;
    top: -$gutter/2;
    right: 0;
    max-width: 184px;
    height: 6px;
    border-radius: $border-radius;
    // invisible border to make the bar visible in larger area.
    // border-bottom: $gutter*2 solid rgba(0, 0, 0, 0);
    box-sizing: content-box;
    // opacity: 0.9;
    // opacity: 0.5;
    // overflow: hidden;
    // @extend .border-radius;
    // transition-delay: $transition-timing*0.5;
    @extend .anim;
    @extend .cursor-pointer;
    @extend .tap-highlight-transparent;
    @include breakpoint_max($sm) {
        // top: $gutter*2;
        top: auto;
        bottom: -$gutter/6;
        left: 0;
        right: 0;
        margin: auto;
        &:hover {
            width: 100%;
            max-width: 100%;
            // top: $gutter;
        }
    }
    // &:not(:hover) {
    //     span {
    //         @extend .box-shadow-blend;
    //     }
    // }
    // allow hover when animation is over, @quikworkaround.     &[style='height: 4px;']:hover {
    &:hover, &.expanded {
        height: $base-metric;
        span {
            color: #fff;
            // background-position-y: -$base-metric*1.5;
            background-image: linear-gradient(rgba(#000, 0.4), rgba(#000, 0.4));
            background-blend-mode: darken;
            // @extend .box-shadow-white;
            &:after, &::before {
                opacity: 1;
            }
            &.active {
                background: $color-error !important;
            }
        }
        &:after {
            opacity: 1;
        }
        &:before {
            background: $color-transparent-black-1;
            // border: 1px solid $color-transparent-white-1;
            @extend .blur-backdrop;
            @include breakpoint_max($sm) {
                bottom: -$gutter/2;
            }
        }
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -$gutter*1.5;
        left: -$gutter/4;
        bottom: -$gutter/4;
        right: -$gutter/4;
        border-radius: $border-radius*2;
    }
    &:after {
        content: "color spectrum";
        position: absolute;
        top: -$gutter*1.25;
        right: 0;
        left: 0;
        color: #fff;
        border-bottom: $gutter solid rgba(0, 0, 0, 0);
        opacity: 0;
        display: inline-block;
        z-index: -1;

        @extend .text-center;
        @extend .text-md;
        @extend .anim;
        @extend .text-shadow;  
    }
    &[data-name]:after {
        content: attr(data-name);
        @include breakpoint_min($md) {
            opacity: 1;
        }
    }
    span {
        $border-radius: $border-radius-inner;
        writing-mode: vertical-lr;
        // display: inline-block;
        display: block;
        height: 100%;
        line-height: 1rem;
        color: transparent;
        // pointer-events: none;
        overflow: hidden;
        background-image: linear-gradient(rgba(#fff, 0.4), rgba(#fff, 0.4));
        background-blend-mode: lighten;
        background-position: 0 0;
        background-repeat: no-repeat;
        @extend .pull-left;
        @extend .text-sm;
        @extend .text-center;
        @extend .text-ellipsis;
        @extend .anim;
        // @include breakpoint_min($md) {
            &:first-of-type {
                border-top-left-radius: $border-radius*2;
                border-bottom-left-radius: $border-radius*2;
            }
            &:last-of-type {
                border-top-right-radius: $border-radius*2;
                border-bottom-right-radius: $border-radius*2;
            }
        // }
        // &[data-hex="#ffffff"] {
        //     background-color: $color-white !important;
        // }
        &:before, &:after {
            opacity: 0;
            font-size: 0.7rem;
            padding: 3px 4px;
        }
        &:before {
            content: attr(data-count);
            font-weight: bold;
            padding-right: $gutter/6;
            border-radius: $border-radius 0 0 $border-radius;
        }
        &:after {
            content: attr(data-color);
            padding-left: 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
        &:before {
            content: attr(data-count);
        }
        &.active {
            box-shadow: 0px -2px 0px 0px var(--color);
            &:before {
                content: 'close';
            }
        }
    }
}

.capture .color-spectrum {
    left: 0;
    margin: auto;
    max-width: 100px;
    // @extend .border-light;
    // border: 1px solid $color-background-base;
}

.color-bubbles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .bubble {
        position: relative;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 10rem;
        height: 10rem;
        font-size: 2rem;
        margin: 2rem;
        // opacity: 0.8;
        // box-shadow: inset 0 5px 10px 5px rgba(255, 255, 255, 0.4), 0 -5px 10px var(--color);
        box-shadow:  0 -5px 30px var(--color);
        // border-top: 24px solid rgba(255, 255, 255, 0.5);
        // border-bottom: 6px solid rgba(0, 0, 0, 0.1);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-radius: 50%;
        &:not(.dummy) {
            background: var(--color, $color-background-base);
            // background: radial-gradient(rgba(255, 255, 255, 0.9), var(--color));
            &:before {
                content: '';
                position: absolute;
                
                top: $gutter;
                left: $gutter;
                right: $gutter;
                bottom: $gutter;
                border: inherit;
                border-radius: inherit;
                background: inherit;
                // filter: blur(1px);
                opacity: 0.1;
                z-index: -1;
                // @extend .anim;
 
                opacity: 1;

                top: -$gutter/2;
                left: -$gutter/2;
                right: -$gutter/2;
                bottom: -$gutter/2;
                // filter: blur(2px);
                border-top-color: transparent;
                border-bottom-color: transparent;
                @extend .animate-rotate-full-circle;
                // @extend .animate-rotate-side;
                animation-duration: 5s;
                animation-timing-function: ease-in-out;
                // animation-direction: alternate;
                animation-delay: var(--delay, 0);
            }
        }
        p {
            margin-bottom: 0;
            mix-blend-mode: difference;
            color: rgb(0, 238, 255);
            // @extend .text-shadow-subtle;
        }
        span {
            font-size: 1rem;
            line-height: 1rem;
            display: block;
            &.label {
                color: #0096e8; // from .bg-confidence
                font-size: 3.5rem;
                line-height: 4rem;
                margin-top: 3rem;
            }
        }
        &.big {
            // padding: 4rem;
            width: 30rem;
            height: 30rem;
            position: absolute;
            top: calc(50% - 15rem);
            left: calc(50% - 15rem);
            // box-shadow: inset 0 5px 10px 5px rgba(255, 255, 255, 0.5), 0 -5px 200px var(--color);
            &:not(.dummy) {
                border-width: 64px;
                z-index: 2;
                &:before {
                    top: -$gutter*3;
                    left: -$gutter;
                    right: -$gutter;
                    bottom: -$gutter*3;
                    opacity: 1;
                    // filter: blur(2px);
                }
                p {
                    // top: -$gutter;
                    position: relative;
                }
            }
        }
    }
}