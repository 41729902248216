// base
$font-size: 16px; // @roboto
$font-size-xxl: 18px;
// $font-size-xxxl: 28px;
$font-size-xxxl: 18px;
$gutter: 24px;
$gutter-big: $gutter*2;

$scale-factor: 1.1;

$cover-path: '~assets/img/cover.jpg';
$cover-home-path: '~assets/img/cover_homepage.jpg';

// colors
$color-chart-background: rgba(255, 255, 255, 0.03);
$color-real-white: #fff;
$color-light: #f1f1f1;
$color-white: #fff;

$color-text: #555;
$color-black: #333;
$color-section: rgb(77, 108, 119);
$color-section: #65919c;
$color-section: #8A2BE2;
$color-section: #af3f3f; // cool red
$color-section: #6D6A94;

$color-accent: #6D6A94;;


// $color-section: rgb(200, 76, 65);
// $color-section: #507BB2;

$color-primary: $color-section;
$color-secondary: #8A2BE2;

$color-primary-transparent: rgba(0, 0, 0, 0.9);

$bg-color-primary: lighten($color-primary, 40);
$bg-color-secondary: #65919c;

$a-color-primary: blue;
$a-color-secondary: #5c3f16;

$h-color-primary: darken($color-primary, 40%);

$bg-color-neutral: #f0f0f0;
$color-neutral: #d3d1cd;
$color-neutral: #f0f0f0;

$color-succes: #519451;
$color-error: #cd5c5c;
$color-muted: #c0c0c0;

$color-alert: #af3f3f;


// colors extra branding
$color-facebook: #3B5998;
$color-twitter: #4598d3;
$color-producthunt: #da552e;

// transparent colors
$color-transparent-black-1: rgba(0, 0, 0, 0.1);
$color-transparent-black-2: rgba(0, 0, 0, 0.2);
$color-transparent-black-3: rgba(0, 0, 0, 0.3);
$color-transparent-black-5: rgba(0, 0, 0, 0.5);
$color-transparent-black-6: rgba(0, 0, 0, 0.5);
$color-transparent-black-7: rgba(0, 0, 0, 0.7);
$color-transparent-black-8: rgba(0, 0, 0, 0.8);
$color-transparent-black-9: rgba(0, 0, 0, 0.9);
$color-transparent-real-black-3: rgba(0, 0, 0, 0.3);
$color-transparent-real-black-5: rgba(0, 0, 0, 0.5);
$color-transparent-real-black-9: rgba(0, 0, 0, 0.9);
$color-transparent-real-black-7: rgba(0, 0, 0, 0.7);
$color-transparent-white-1: rgba(255, 255, 255, 0.1);
$color-transparent-white-3: rgba(255, 255, 255, 0.3);
$color-transparent-white-5: rgba(255, 255, 255, 0.5);
$color-transparent-white-7: rgba(255, 255, 255, 0.7);
$color-transparent-white-9: rgba(255, 255, 255, 0.9);


$color-overlay: $color-transparent-black-9;
$color-secondary-transparent: $color-secondary;
$color-transparent-muted: inherit;
$color-transparent-white: inherit;

// $color-real-muted: inherit;
$color-real-white: #fff;

// border radius
$border-radius: $gutter/4;
$border-radius-inner: $border-radius/2;

// transition settings
$animation-timing: 0.55s;
$animation-timing: 0.5s;
$transition-timing: 0.3s; // before cubic-bezier.
$transition-timing: 0.3s;
$transition-timing-faster: 0.3s;

$transition-delay: 0.4s;
$transition-timing-function: linear;
// http://cubic-bezier.com/#0,.99,.72,.91
$transition-timing-function: cubic-bezier(0.75, 0.5, 0.75, 0.5);
$transition-timing-function: ease-in-out;
$transition-timing-function: cubic-bezier(.44,.68,.38,.9);
// $transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

// breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1920px;
// $v2k: 3200px;
$xxxl: 2400px;
$v4k: 3200px;
$v5k: 5000px;

$max-width: 1400px;
$max-width-xxl: 1600px;
$max-width-xxxl: 2000px;
$max-width-xxxl: 1600px;