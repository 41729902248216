.phone-search {
    $color-white: $color-primary;
    z-index: 101;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 1px solid transparent;
    margin: 0 $gutter/3;
    background: #fff;
    font-size: 1rem;
    // line-height: 1em;
    max-width: 600px;
    overflow: hidden;
    @extend .text-left;
    @extend .text-primary;
    @extend .border-radius;
    &.pure {
        border-color: transparent;
        background: transparent;
        .user-input {
            background: transparent;
        }
        input {
            color: $color-white !important;
        }
    }
    &:not(.pure) {
        border: 1px solid lighten($color-muted, 30%);
        @extend .box-shadow;
    }
    @include breakpoint_min($md) {
        margin: 0 $gutter/3;
    }
    .user-input {
        z-index: 0;
        position: relative;
        padding: 0 $gutter/2;
        // background: $color-neutral;
        box-sizing: content-box;
        line-height: 3em;
        // line-height: 2.6em;
        font-size: 1.3em;
        overflow: hidden;
        caret-color: $color-white;

        // @extend .anim;
        /*
        &::before {
            z-index: -1;
            content: '';
            background: $color-succes;
            width: 100%;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            transform: translateY(50px);
            opacity: 0;
            @extend .anim;
        }
        &.isFetching {
             color: #fff;
             transition-delay: 0.75s;
            &::before {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0.75s;
            }
        }
        */
        span {
            display: inline-block;
            // margin-right: $gutter/6;
            padding: $gutter/4;
            // border-left: 12px solid $color-secondary !important;
            background: $color-transparent-white-1;
            // background: $color-secondary;
            // color: $color-secondary;
            color: $color-muted;
            border: 1px solid $color-transparent-white-1;
            border-radius: 50%;
            // @extend .border-radius;
        }
        input {
            background: transparent;
            border: 0;
            width: calc(100% - #{$gutter*3});
            padding: $gutter/4;
            color: inherit;
            color: $color-primary;
            font-size: inherit;
            font-weight: 300;
            @extend .font-user-content;
            // &::placeholder {
            //     color: $color-white;
            // }
        }
    }
    .search-count {
        position: absolute;
        right: 0;
        top: $gutter/1.2;
        color: $color-muted;
        @extend .text-sm, .text-uppercase, .text-right, .padding-horizontal;
    }
    .result {
        padding: 0;
        @extend .anim;
        &:not(.active) {
            opacity: 0;
            transform: translateY($gutter*1);
        }
        p {
            padding: $gutter/2;
            font-size: 1em;
        }
        ul {
            $color-highlight: darken($color-secondary, 30);
            padding: 0;
            margin: 0;
            overflow: hidden;
            overflow-y: auto;
            max-height: 50vh;
            // @extend .text-primary;
            li {
                overflow: hidden;
                background: #fff;
                box-shadow: 0 -1px 0 $color-neutral;
                cursor: pointer;
                padding: 0 $gutter/2;
                list-style: none;
                display: flex;
                align-items: center;
                line-height: 50px; // fixed
                color: darken($color-secondary, 30);
                &:last-of-type {
                    box-shadow: 0 -1px 0 $color-neutral, inset 0 -2px 0px 0px $color-neutral;
                }
                &:hover, &:focus {
                    background: lighten($color-neutral, 4);
                    color: $color-highlight;
                }
                &:focus {
                    // outline: 1px solid $color-muted;
                    color: $color-succes;
                    outline: none;
                }
                &.inactive {
                    &:not(:hover) {
                        background: rgba(0, 0, 0, 0.05);
                    }
                    img, span {
                        color: $color-muted; 
                    }
                }
                b {
                    color: $color-highlight;
                    @extend .stronger;
                }
                /**
                // apply hover only on the elements with opacity 1.
                &:hover[style*="opacity: 1"] {
                    background: linear-gradient(90deg, #fff 0%, #fefefe 100%);
                    outline: 1px solid darken($color-neutral, 5);
                    outline-offset: $gutter;
                    outline-color:transparent;
                    @extend .anim;
                }
                **/
                img {
                    background: $color-neutral;
                    // @extend .text-right;
                    height: 38px;
                    width: 29px;
                    margin-right: $gutter/2;
                }
                div {
                    color: $color-succes;
                    font-size: 1em;
                    flex-shrink: 1;
                    text-align: right;
                    flex-grow: 1;
                    display: none;
                }
                &.exists {
                    div {
                        display: block;
                    }
                }
            }
        }
    }
}

header {
    $color-muted: $color-white;
    .search-bar {
        padding: 0 $gutter*1.5;
        padding-right: $gutter;
        $thick: 2px;
        $base-metric: $gutter*0.8;
        $angle: 45deg; 
        @include breakpoint_max($md) {
            padding: 0 !important;
            span {
                display: none;
            }
        }
        span {
            // vertical-align: middle;
            // padding: 0 $gutter/2;
            float: left;
            opacity: 0;
            color: $color-muted;
            width: $gutter*3;
            display: none;
            // @extend .anim;
        }
        a {
            $color: $color-muted;
            position: relative;
            background: transparent;
            display: inline-block;
            width: $base-metric;
            height: $base-metric;
            vertical-align: sub;
            border: $thick solid $color;
            border-radius: 50%;
            cursor: pointer;
            user-select: none;
            @extend .anim;
            @include breakpoint_max($md) {
                opacity: 0;
            }
            &:after {
                content: '';
                position: absolute;
                width: $base-metric/2;
                height: $thick;
                background: $color;
                bottom: 0;
                left: $base-metric/1.5 + $thick;
                transform: rotateZ($angle); 
                transform-origin: left;
                @extend .anim;
            }
            &:before {
                content: '';
                opacity: 0;
                position: absolute;
                top: -0;
                right: 0;
                width: 0;
                height: $thick;
                background: $color;
                transform: rotateZ(-$angle);
                transform-origin: right;
                @extend .anim;
            }
            &:hover {
                border-color: $color-real-white;;
                &:after, &:before {
                    background: $color-real-white;
                }
            }
        }
        input {
            width: 0;
            padding: $gutter/2 $gutter/2;
            opacity: 0;
            margin: 0;
            border: 0;
            border: 1px solid $color-white;
            border-radius: 0;
            // background: rgba($color-secondary, 0.2);
            pointer-events: none;
            margin-right: -$gutter*1.2;
            border-radius: $border-radius;
            color: $color-white;
            display: inline-block;
            transform: translate3d(0);
            background: #fff;
            color: $color-primary;
            // vertical-align: middle;
            font-size: 1rem;
            @extend .anim; 
        }
        &.active {
            // background: red;
            &.has-results {
                span {
                    opacity: 1;
                    transition-delay: $animation-timing*2;
                }
            }
            span {
                display: block;
            }
            input {
                width: 300px;
                opacity: 1;
                padding-right: $gutter;
                pointer-events: auto;
                @include breakpoint_max($lg) {
                    width: 200px;
                }
            }
            a {
                $color: $color-error;
                border-color: transparent;
                &:before {
                    opacity: 1;
                    width: $base-metric + $thick/2;
                    background: $color;
                }
                &:after {
                    width: $base-metric + $thick/2;
                    background: $color;
                    transform: rotateZ($angle) translateX(-$base-metric);
                }
            }
        }
    }
}

header.sticky + .search-results {
    top: 0;
}

.search-results {
    $offset: $gutter*6;
    z-index: 11;
    position: fixed;
    top: $gutter*3.5;
    @include breakpoint_min($md) {
        top: $gutter*5;
        top: 0;
        position: -webkit-sticky;
        position: sticky;
        border-top: $gutter*3 solid $header-background;
        height: 100vh;
        // margin-left: -$gutter;
        // margin-right: -$gutter;
	} 
    left: 0;
    right: 0;
    height: 80vh;
    overflow: hidden;
    // pointer-events: none;
    background: $color-light;
    overflow-y: auto;
    @extend .scrollbar;
    @extend .padding;
    
    &.active {
        opacity: 1;
        &.has-results {
            pointer-events: auto;
            @extend .box-shadow;
        }
        + div {
            &:after {
                content: '';
                position: fixed;
                z-index: 10;
                // background: darken($header-background, 10%);
                background: $color-light;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                transition-delay: 1s;
                @extend .anim;
            }
        }
    }
}
