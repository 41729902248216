.user-cards {
    @mixin active-item() {
        flex: 10; // @quikfix temporary solution
        // padding-left: $gutter;
        // padding-right: $gutter;
        padding-right: $gutter/2;
        // border-left: 4px solid $color-transparent-black-1;
        // transform: perspective(0) rotateY(0);
        // pointer-events: all;
        // background: linear-gradient(to bottom,  $color-succes 0%, $color-light 68%);
        @extend .bg-white, .box-shadow, .border-radius;
        .user-name {
            color: $color-primary;
            // @extend .strong;
        }
        // > * {
        //     opacity: 1;
        // }
        .inner {
            // border-radius: $border-radius;
            opacity: 1;
            // filter: blur(0);
            border-radius: 0;
            // padding: 0 $gutter;
        }
        .btn {
            opacity: 1;
        }
        @include breakpoint_min($md) {
            flex: 3;
        }
        @include breakpoint_min($lg) {
            flex: 2;
        }
        @include breakpoint_min($xl) {
            flex: 1.5;
            flex: 1.1;
            // max-width: 250px;
        }
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    // overflow: hidden;
    .user-card {
        flex: 1;
        overflow: hidden;
        margin: 0;
        border: 1px solid transparent; // fixes flex gap glitch
        border-bottom: none;
        border-top: none;
        box-sizing: border-box;
        padding: $gutter 0;
        padding-left: $gutter/2;
        // border-right:1px solid $color-accent;
        // pointer-events: none;
        user-select: none;
        // min-height: 300px;
        // background: linear-gradient(to bottom,  $color-white 0%, $color-light 68%);
        // transform: perspective(1000px) rotateY(40deg);
        // transition: flex $transition-timing $transition-timing-function;
        @extend .anim;
        // @extend .padding-sm;
        // @extend .text-center;
        @include breakpoint_min($lg) {
            padding-left: $gutter;
        }
        &:hover {
            @include active-item;
        }
        > .inner {
            opacity: 0.3;
        }
        .user {
            margin-top: 0;
        }
        p.text-separator {
            line-height: 1rem;
        }
        .user-name {
            font-size: 1.2rem;
            line-height: 1.5rem;
            margin-top: $gutter/2;
            margin-bottom: $gutter/2;
            // color: $color-white;
            @extend .text-nowrap;
        }
        blockquote {
            overflow: hidden;
            width: 70vh;
        }
        .inner {
            // height: 200px;
            text-align: left;
            opacity: 0.1;
            // filter: blur(3px);
            transform-origin: top left;
            // @extend .pattern;
            @extend .anim;
            @extend .text-nowrap;
        }
        .btn {
            opacity: 0;
            margin-top: $gutter/2;
            transition: none;
            // @extend .anim;
            &:hover {
                transform: none;
            }
        }
    }
    &:not(:hover) {
        .user-card {
            &.active {
                @include active-item;
            }
        }
    }
    .user img {
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);
    }
}