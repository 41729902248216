$width: 197px;
.phones {
    @extend .clearfix;
    &.editable {
        margin: 0;
        padding: $gutter;
        @include breakpoint_max($sm) {
            padding: $gutter/2;
        }
        .phone {
            &:hover {
                .phone-inner {
                    // transform: translateY($gutter*1.2);
                    transform: perspective(300px) rotateX(10deg);
                    // opacity: 0.6;
                }
            }
            .phone-inner {
                transform-origin: bottom;
                @extend .anim;
            }
        }
    }
    // &:not(.editable) {
    //     .phone-inner {
    //         &:hover img, &:hover .img {
    //             // transform: scale(3.9);
    //             // filter: blur(5px);
    //             transform: scale($scale-factor);
    //             // filter: blur(5px);
    //         }
    //     }
    // }
    &.centered {
        @extend .text-center;
        .phone {
            display: inline-block;
            float: none;
        }
    }
    // the idea is that we dont want to waste space on small screens.
    @include breakpoint_max($sm) {
        margin-left: -$gutter/3;
        margin-right: -$gutter/3;
    }
}
.phone {
    position: relative;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.3em;
    width: 188px;
    width: 220px;
    width: $width;
    // overflow: hidden;
    //@include breakpoint_max($md) {
    //    width: 150px;
    //}
    padding: 0 $gutter/4;
    margin-top: $gutter*0.75;
    margin-bottom: $gutter*0.75;
    @extend .border-radius;
    // @extend .anim;
    $color-to: transparent; // $color-light
    &.y, &.n, &.na, &.nexam-or-not {
        // border-top: 2px solid lighten($color-muted, 10%);
        // background: linear-gradient(to bottom,  rgba($color-muted, 0.2) 0%, $color-to 68%);
        // background: linear-gradient(to bottom,  lighten($color-muted, 20%) 0%, $color-to 68%);
        @extend .text-center;
        .phone-inner {
            border-top: 2px solid lighten($color-muted, 25%);
            // border-top: 2px solid $color-neutral; // @aero
        }
    }
    &.y {
        // border-top-color: lighten($color-succes, 20%);
        .phone-inner {
            border-top-color: lighten($color-succes, 20%);
        }
    }
    &.n {
        // border-top-color: lighten($color-error, 20%);
        .phone-inner {
            border-top-color: lighten($color-error, 20%);
        }
    }
    // &.y  {
    //     background: linear-gradient(to bottom,  rgba($color-succes, 0.3) 0%, $color-to 68%);
    // }
    // &.n  {
    //     background: linear-gradient(to bottom,  rgba($color-error, 0.3) 0%, $color-to 68%);
    // }
    // &.na  {
    //     background: linear-gradient(to bottom,  rgba($color-muted, 0.2) 0%, $color-to 68%);
    // }
    // &.nexam {
        // background: linear-gradient(to bottom,  rgba($color-secondary, 0.2) 0%, $color-to 68%);
    // }
    &.phone-with-brand, &:not(.lolquikfix) {
        > p, > div > p {
            line-height: 1.8rem;
            text-align: left;
            padding-left: $gutter/2;
            margin-bottom: 0;
        }
    }
    .phone-inner {
        position: relative;
        // overflow: hidden; // we need cos of ratings bar.
        padding-bottom: $gutter*0.5;
        // margin-bottom: $gutter*0.5;
        margin-bottom: 0;
    }

    @include breakpoint_max($md) {
        // &:nth-child(even) {
        //     padding-left: $gutter/1.5;
        // }
        // &:nth-child(odd) {
        //     padding-right: $gutter/1.5
        // }
        width: 50%;
        margin-left: $gutter/5;
        margin-right: $gutter/5;
        padding: 0 $gutter/8;
        width:  calc(50% - #{$gutter/5}*2);
    }

    @include breakpoint_min($md) {
        // some stiles for ipad & desktop
        padding-left: $gutter/6 !important;
        padding-right: $gutter/6 !important;
        font-size: 1rem;
        // text-transform: uppercase;
        // &:after {
        //     content: '';
        //     position: absolute;
        //     border-radius: $border-radius;
        //     z-index: -1;
        //     // width: $gutter*4;
        //     height: $gutter*1.5;
        //     top: -3px;
        //     right: -2px;
        //     left: 40%;
        //     right: 40%;
        //     background: rgba($color-secondary, 0.2);
        // }
     
        // that's all
        width: calc(#{percentage(1/3)} - #{$gutter});
    }
    &.phone-gone {
        .phone-inner:before {
            content: 'gone';
            z-index: 0;
            position: absolute;
            background: $color-transparent-black-3;
            padding: 0 $gutter/4;
            color: #fff;
            bottom: $gutter*2;
            // left: 50%;
            // transform: translateX(-50%);

            right: -$gutter/2.5;
            transform: rotateZ(270deg);
            transform-origin: center;

            border-radius: $border-radius $border-radius 0 0;
            // @extend .text-center;
            // @extend .text-uppercase;
            @extend .text-sm;
        }
    }
    @include breakpoint_min($lg) {
        width: calc(#{percentage(1/5)} - #{$gutter});
    }
    @include breakpoint_min($max-width) {
        width: calc(#{percentage(1/6)} - #{$gutter});
    }
    // @include breakpoint_min($xxl) {
    //     width: calc(#{percentage(1/7)} - #{$gutter});
    // }
    
    // add extra margins on higher res.
    @include breakpoint_min($md) {
        margin: $gutter/2;
        margin-top: $gutter*0.5;
        margin-bottom: $gutter;
        padding: 0;
    }
    @include breakpoint_min(0) {
        .phone-inner {
            background: #fff;
            padding: $gutter 0;
            // margin-bottom: $gutter/2;
            border-radius: $border-radius;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
            // border: 1px solid darken($color-light, 3%);
        }
    }

    @extend .pull-left;
    @extend .text-center;
    @extend .font-distinct;
    
    &:hover {
        //> *:not(:focus):not(.rate):not(p) {
        //}
        .phone-color {
            opacity: 1;
        }
        .rate {
            &:not(:focus) {
                // pointer-events: all;
                opacity: 1;
                transform: scaleY(1) rotate(0);
            }
        }
        .edit {
            z-index: 101 !important;
            > .control {
                pointer-events: auto;
                opacity: 1;
                transform: translateY(0);
                @extend .transform-origin-x-50;
                button {
                    @extend .text-shadow;
                }
            }
        }
    }
    &.add {
        // plus
        color: $color-succes;
        pointer-events: all !important; // overrides .disable-actions
        @extend .tap-highlight-transparent;
        user-select: none;
        // transition: none;
        transition-delay: 0;
        // @extend .anim;

        &:hover {
            color: $color-succes;
            > span {
                border: 1px solid $color-succes;
                // @extend .box-shadow-light;
            }
            @extend .cursor-pointer;
        }
        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 6em;
            border-radius: 50%;
            color: $color-succes;
            border: 1px solid $color-succes;
            background: #fff;
            width: 1.2em;
            height: 1.2em;
            // line-height: 1.2em;
            padding: 0;
            margin: $gutter*3 auto;
            margin-bottom: $gutter/2;
            transition-delay: 0;
            padding-bottom: 7px; // fix center due font line height
            @extend .anim;
        }
        &.new {
            color: $color-error;
            > span {
                color: $color-error;
                border: 1px solid $color-error;
                // background: transparent;
                transform: rotateZ(-45deg);
            }
        }
    }
    .rate {
        z-index: 3;
        position: absolute;
        // pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transform: scaleY(0) rotateY(45deg);        
        opacity: 0;
        transform-origin: bottom;
        // transform: scale(0.5);
        @extend .anim;
        @extend .tap-highlight-transparent;
        &:focus {
            outline: none;
        }
        button {
            //width: 50%;
            margin-top: $gutter/2;
            position: relative;
            padding: $gutter/4;
            &.btn-transparent {
                background: $color-transparent-real-black-9;
            }
            &:hover ix {
                color: #fff;
                transform: scale(2.2);
            }
            &[value="y"] {
                color: $color-succes;
                &:hover {
                    color: #fff;
                    background: $color-succes !important;
                }
            }
            &[value="n"] {
                color: $color-error;
                &:hover {
                    color: #fff;
                    background: $color-error !important;
                }
            }
            i {
                margin-top: $gutter;
                font-size: 1.3em;
                padding: 0;
            }
            span {
                // use this on parent 
                // pointer-events: none;
                // use this on children where we want to animate PARENT stuff.
                // pointer-events: auto;
                position: absolute;
                top: $gutter/4;
                color: $color-white;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 0.7em;
                // @extend .text-muted;
                // &:hover {
                //     color: #fff;
                // }
            }
        }
    }
    .edit {
        position: absolute;
        z-index: 3;
        top: -$gutter/2;
        bottom: $gutter/4;
        left: -$gutter/4;
        right: -$gutter/4;
        pointer-events: none;
        .padding-horizontal {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .form, .overlay-screen {
            pointer-events: auto;
        }
        > .control {
            z-index: 99;
            position: absolute;
            width: 100%;
            top: 0;
            opacity: 0;
            pointer-events: none;
            transform: translateY($gutter/3);
            @extend .anim;
            button {
                color: $color-real-white;
                &:hover {
                    transform: scale($scale-factor);
                }
                &.control-edit:hover, &.control-edit {
                    @extend .text-secondary;
                }
                &.control-delete:hover, &.control-delete {
                    @extend .text-error;
                }
            }
        }
    }
    .form {
        // z-index: 1;
        position: absolute;
        background: #fff;
        // background: rgba(#fff, 0.95);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: $gutter/4 $gutter/2;
        border-radius: $border-radius;
        border-top: 2px solid $color-primary;
        text-align: left;
        @extend .box-shadow;
        @include breakpoint_min($md) {
            padding: $gutter/2;
        }
        .control {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: $gutter/4 $gutter/2;
            .btn-link {
                // vertical-align: top;
                margin: 0;
                padding: 0;
            }
        }
        .input-group {
            justify-content: space-between;
        }
    }
    img, .img {
        // width: 132px;
        // height: 174px;
        // because we have 2 rows for brand and phone, we have to shrink the image a bit.
        width: 122px;
        height: 164px;
        height: 160.81px;
        // @extend .anim;
    }
    img {
        &:not([src]) {
            // transparent pixel, tks to https://css-tricks.com/snippets/html/base64-encode-of-1x1px-transparent-gif/
            content: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
            background: $color-light;
            border: none;
            @extend .border-radius;      
        }
    }
    .img {
        background-size: contain;
        margin: auto;
        background-repeat: no-repeat;
    }
    .phone-color {
        position: absolute;
        // z-index: 2;
        // background: repeating-linear-gradient(transparent 0, rgba(0, 0, 0, 0.2) $gutter*2, transparent 1px);
        background: linear-gradient(to left, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1));
        background-blend-mode: luminosity;

        // border-radius: 50%;
        border-radius: 4px;
        border: 2px solid #fff;
        // border-radius: 0;
        right: $gutter/3;
        bottom: -$gutter*1.08;
        width: $gutter/1.6;
        height: $gutter/1.6;
        opacity: 0.5;
        // @extend .box-shadow;
        @extend .anim;
        &:hover {
            &:before {
                opacity: 1;
                transform: translateX(0);
            }
        }
        &:before {
            content: attr(data-name);
            position: absolute;
            display: block;
            right: $gutter/2.1;
            bottom: -2px;
            height: $gutter/1.6;
            font-size: 0.65rem;
            line-height: 1rem;
            background: #fff;
            padding-left: 4px;
            padding-right: $gutter/4;
            border-radius: $border-radius 0 0 $border-radius;
            opacity: 0;
            transform: translateX(2px);
            transform-origin: right;
            user-select: none;
            @extend .anim;
        }
    }
    > div > p, > p {
        // font-size: 1.15em;
        font-size: 1.15em;
        line-height: 2.2rem;
        color: $color-primary;
        // font-weight: 400;
        @extend .text-nowrap;
        @include breakpoint_max($sm) {
            font-size: 1em;
        }
        a, span {
            color: $color-black;
            // @extend .text-muted;
        }
        // &.y a, &.y span {
        //     @extend .text-success;
        // }
        // &.n a, &.n span {
        //     @extend .text-error;
        // }
        // &.na a, &.na span {
            // @extend .text-muted;
        // }
        &.direction-rtl {
            // padding: 0 $gutter/12;
            text-overflow: ellipsis;
            // add &lrm; to fix "+" being moved to start.
        }
        .brand {
            display: block;
            line-height: 1rem;
            // font-size: 0.75em;
            font-size: 0.74em;
            padding-top: $gutter/4;
            @extend .text-uppercase;
            // @extend .strong;
            @extend .stronger;
        }
    }
    // stats (eg: years, owners)
    .year {
        font-size: 0.8em;
        text-align: left;
        padding-left: $gutter/2;
        a.generic/**link**/ {
            font-size: 1.2em;
            // @extend .text-accent;
        }
        @extend .text-muted;
        &.error {
            @extend .text-error;
        }
    }
    .main-device {
        // position: absolute;
        // bottom: 3px;
        // margin-left: -40%;
        // left: 0;
        // right: 0;
        padding-left: $gutter/6;
        @extend .cursor-help;
        @extend .text-success;
    }
}

// when we are in my showcase we want some stiyling
// .my-showcase {
    .phone {
        &.y {
            button {
                &[value="y"] {
                    background: $color-succes;
                    color: $color-real-white;
                }
            }
        }
        &.n {
            button {
                &[value="n"] {
                    background: $color-error;
                    color: $color-real-white;
                }
            }
        }
    }
// }

.bg-light .phones {
    @extend .bg-light;
}

// fix when delating a phone.
.to-0 .edit {
    opacity: 0;
}

// .new-phone-container + .ask-for-opacity {
//     padding-top: $gutter;
// }

.ask-for-opacity {
    @extend .bg-light;
    &.ask-for-opacity-now {
        .phone:not(.new) {
            @extend .disable-actions;
            .phone-inner {
                // border-top-color: $color-light;
                @extend .overlay-bg-white-fix;
            }
            img, p, .year, .phone-color, i {
                opacity: 0.5;
                // transition-delay: $animation-timing;
                // filter: grayscale(1) brightness(0.97); 
            }
        }
    }
    @include breakpoint_min($md) {
        .phones {
            min-height: $width * 3;
        }
    }
}

.full-section .phones {
    .phone:last-of-type {
        @include breakpoint_min($lg) {
            display: none;
        }
        @include breakpoint_min($xxl) {
            display: inline-block;
        }
    }
}

// @quikdamnfix
.capture .phone {
    width: calc(#{percentage(1/6)} - #{$gutter}) !important;
}