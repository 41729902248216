.device {
    $phone-width: 200px;
    > :first-child {
        @extend .font-distinct;
        @extend .font-weight-300;
    }
    .block {
        display: flex;
        color: rgba($color-muted, 0.9);
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        // flex-flow: column wrap;
        // justify-content: flex-end;
        // justify-content: space-around;
        @include breakpoint_max($lg) {
            // flex-flow: row wrap;
            flex-direction: column-reverse;
        }
        .btn {
            opacity: 0.9;
        }
        .at-a-glance {
            //flex-wrap: nowrap;
            //white-space: nowrap;
            // 
            /* don't grow, don't shrink, fixed 500px */
            // flex: 0 0 540px; /** auto **/
            flex: 0 0 42%; /** auto **/
            min-width: 540px;
            @include breakpoint_max($lg) {
                // background: #fff;
                // background: $color-light;
                // border-top: 1px solid $color-transparent-white-1;
                background: linear-gradient($color-chart-background, transparent);
                flex: auto;
                min-width: auto;
                margin: -$gutter/2;
                margin-top: 0;
                padding-top: $gutter/2;
                margin-bottom: $gutter/2;
                > * {
                    margin-top: -$gutter/2;
                }
            }
            @include breakpoint_min($lg) {
                border-right: 1px solid $color-transparent-white-1;
                margin-right: $gutter;
                padding-right: $gutter;
                .text-md {
                    color: $color-white;
                }
            }
            > div + div:not(.clearfix) {
                margin-left: $gutter/2;
                margin-top: $gutter;
                width: 40%;

                @include breakpoint_min($lg) {
                    width: 160px;
                    margin-left: $gutter;
                }
                @include breakpoint_min($xl) {
                    width: 240px;
                }
                @include breakpoint_min($xxl) {
                    width: 260px;
                }
            }
            // > div:last-child {
            //     padding-top: $gutter;
            // }
            @extend .clearfix;
        }
        .chart-container {
            flex-grow: 1;
            // width: 100%;
        }
        .chart-section {
            @include breakpoint_max($lg) {
                margin-top: -$gutter/2;
                margin-left: -$gutter/2;
                margin-right: -$gutter/2;
            }
        }
        .phone {
            padding: $gutter/2;
            width: $phone-width;
            margin-bottom: $gutter/4;
            p, a, span {
                color: $color-neutral;
            }
            p > span, p > a {
                display: none;
            }
            .year {
                display: none;
            }
        }
        .cta-add-phone {
            position: absolute;
            z-index: 2;
            // display: inline-block;
            left: $phone-width - $gutter*1.2;
            top: $gutter/4;
            border-radius: 50%;
            background: #fff;
            width: 2.4rem;
            height: 2.4rem;
            line-height: 2.4rem;
            font-size: 1.8rem;
            text-align: center;
            text-shadow: none;
            @extend .box-shadow;
            @include breakpoint_min($md) {
                left: $phone-width - $gutter/2; 
            }   
            a {
                display: block;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    display: flex;
                    align-items: center;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: #fff;
                    padding-left: $gutter;
                    border-radius:  $gutter;
                    font-size: 0.9rem;
                    pointer-events: none;
                    @extend .text-nowrap;
                    @extend .anim
                }
                &:hover {
                    &:before {
                        content: 'add to showcase';
                        right: -$gutter*5;
                        padding-left: $gutter*1.5;
                        pointer-events: auto;
                    }
                }
            }
        }
    }
}