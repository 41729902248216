.warnings {
    position: absolute;
    right: $gutter/3;
    left: $gutter/3;
    bottom: 0;
    > div {
        position: absolute;
        z-index: 99;
        visibility: hidden;
        opacity: 0;
        background: #fff;
        // background: lighten($color-error, 40%);
        --color: #{$color-error};
        --color: #{$color-transparent-black-3};
        border: 1px solid lighten($color-error, 0%);
        // border: 1px solid $color-error;
        right: -$gutter/3;
        left: -$gutter/3;
        bottom: -$gutter/6;
        padding: $gutter/4;
        padding-bottom: $gutter/8;
        // padding-right: $gutter*1.1;
        text-align: left;
        line-height: 1.3em;
        pointer-events: none;
        transform: translateY(0) scale(0);
        transform-origin: bottom right;
        @extend .text-sm;
        // @extend .box-shadow-blend;
        @extend .box-shadow-blend;
        @extend .border-radius;
        @extend .anim;
        p {
            margin-top: 0;
            margin-bottom: $gutter/4;
        }
        span {
            font-size: 0.9em;
            @extend .text-uppercase;
            @extend .strong;
            @extend .text-error;
        }
    }
    i {
        position: relative;
        z-index: 100;
        vertical-align: middle;
        @extend .text-error;
    }
    i:hover ~ div, div:hover {
        visibility: visible;
        opacity: 1;
        transform: translateY(0) scale(1);
        pointer-events: auto;
    }
}