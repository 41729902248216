.cookie {
    // position: -webkit-sticky;
    // position: sticky;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-transparent-black-9;
    // background: $color-primary-transparent;
    padding: $gutter/6 $gutter/2;
    font-size: 1rem;
    line-height: 1.3rem;
    color: $color-white;
    // max-height: 500px;
    max-height: 250px;
    // @extend .bg-transparent-main;
    // @extend .anim;
    &.hidden {
        // bottom: -50%;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        visibility: hidden;
        margin-top: 0;
        @extend .anim;
    }
    .btn {
        margin: $gutter/2 $gutter/2;
        margin-bottom: 0;
    }
    p {
        padding: 0 $gutter/2;
    }
    span {
        display: block;
        @include breakpoint_min($xl) {
            float: right;
        }
        @include breakpoint_max($xl) {
            margin-top: $gutter/4;
        }
    }
    a {
        font-size: 1.1rem;
        &:not(:first-of-type) {
            margin: 0 $gutter;
        }
    }
    // @include breakpoint_min($md) {
	// 	margin-left: -$gutter;
	// 	margin-right: -$gutter;
	// } 

	// @include breakpoint_min($xxl) {
	// 	margin-left: -$gutter*2;
	// 	margin-right: -$gutter*2;
    // } 
    
}

// @cookie @cookie-top
@include breakpoint_min($lg) {
    .header-not-sticky {
        .cookie {
            opacity: 0;
            top: 0;
            bottom: auto;
            // padding: $gutter/8 0;
            // border-bottom: 2px solid $color-background-base;
            background: $color-background-base;;
            // margin: 0;
            // box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            p {
                color: rgba(255, 255, 255, 0.5);
                padding: 0 $gutter;
                max-width: $max-width + $gutter * 4;
                @include breakpoint_min($xxl) {
                    max-width: $max-width-xxl + $gutter * 4;;
                }
                a {
                    color: rgba(255, 255, 255, 0.7);
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
            &.animate-fade-in {
                animation-name: fade-in-negative;
            }
        }
        .cookie.visible:not(.hidden) ~ header {
            padding-top: $gutter*2;
        }
        // .sticky ~ .cookie {
        //     padding: $gutter/4;
        //     font-size: 1.2rem;
        //     line-height: 1.3rem;
        //     width: 100%;
        //     left: 0;
        // }
    }
}
.cookie ~ header {
	transition: all $transition-timing $transition-timing-function !important;
}