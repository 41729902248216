/** this component is wrongly named **/
.progress-bar {
    position: relative;
    width: 100%;
    // margin: $gutter/6 auto;
    height: 2px;
    opacity: 1;
    // border-radius: $border-radius;
    border-radius: 0;
    background: $color-neutral;
    user-select: none;
    @extend .anim;
    &:not(.empty) {
        background: $color-error;
        // overflow: hidden;
        > div {
            position: absolute;
            height: 100%;
            background: $color-succes;
            @extend .anim;
        }
    }
    &.progress-bar-neutral {
        height: 3px;
        background: $color-transparent-white-1;
        > div {
            background: $color-white;
        }
    }
}

.bg-white, .bg-light {
    .progress-bar.progress-bar-neutral {
        background: $color-light;
        > div {
            background: $color-primary;
        }
    }
}

.bg-light {
    .progress-bar.progress-bar-neutral {
        background: $color-white;
    }
}

// @externals
.phone {
    .progress-bar {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 1.5px; // fix for no overflow hidden
        right: 1.5px;
        width: auto;
        opacity: 0;
        // overflow: hidden;
        @extend .border-radius-bottom;
        > div {
            @extend .border-radius-bottom;
        }
    }
    &.nexam {
        .progress-bar {
            opacity: 0.6;
        }
    }
    &:hover {
        .progress-bar:not(.empty) {
            opacity: 1;
            // height: $gutter/4;
        }
    }
}

.at-a-glance {
    .phone-inner {
        overflow: initial;
    }
    // .progress-bar {
    //     top: -$gutter;
    //     height: 6px;
    //     border-radius: $border-radius;
    //     opacity: 1 !important;
    // }
}

.device {
    .progress-bar.wide {
    //  display: none;
        position: absolute;
        top: 0;
        // bottom: -$gutter*2.6; // @tabpane nav
        left: 0;
        right: 0;
        height: 4px;
        opacity: 0.7;
        opacity: 1;
        z-index: 2;
        // --color: #{$color-white};
        // @extend .box-shadow-blend;
        // border-top: 4px solid rgba($color-background-base, 0.9);
        // @include breakpoint_min($md) {
        //     border-radius: $border-radius $border-radius 0 0;
        // }
    }
    .progress-bar.overlay:not(.wide) {
        width: 50%;
        max-width: 140px;
        height: 6px;
        margin-left: auto;
        overflow: hidden;
        // --color: #{$color-white};
        // @extend .box-shadow-blend;
        @extend .border-radius;
    }
}