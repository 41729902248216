.account {
    max-width: $lg;
    margin: auto;
    font-size: 1rem;
    background: transparent;
    // opacity: 0.99;
    color: $color-succes;
    @extend .text-center;
    @extend .font-distinct;
    @extend .font-weight-300;
    @extend .clearfix;
    .block {
        position: relative;
        // opacity: 0;
        width: 46%;
        margin: 2%;
        padding: $gutter $gutter;
        border-radius: $border-radius;
        // min-height: 400px;
        background: #fff;
        @extend .pull-left;
        @extend .box-shadow;
        @include breakpoint_max($md - 1) {
            width: 100%;
            margin: 0 0%;
            margin-top: $gutter*2;
        }
        &:first-of-type {
            background: lighten($color-succes, 40%);
        }
        &:last-of-type {
            color: $color-secondary;
            background: $color-real-white;
        }
        > p:first-of-type {
            font-size: 2em;
            padding: $gutter $gutter/4;
        }
        h3 {
            padding-top: 0;
        }
        > i {
            font-size: 6em;
              //mix-blend-mode: overlay;
        }
    }
}