.navbar {
    a {
        position: relative;
        color: $color-white;
        padding: $gutter/2;
        font-size: 0.9rem;;
        @extend .stronger;
        @extend .text-uppercase;
        &::before {
            content: '';
            position: absolute;
            background: #fff;
            transform: scale(0);
            transform-origin: center;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            @extend .border-radius;
        }
        &:hover, &.cta {
            color: $color-primary;
            &::before {
                $base-metrics: 2px;
                top: $base-metrics;
                bottom: $base-metrics;
                left: $base-metrics;
                right: $base-metrics;
                opacity: 1;
                transform: scaleY(1);
                // transition: none;
                @extend .anim;
            }
        }
    }
    @include breakpoint_max($lg) {
        a:not(.cta):not(.hide-md) {
            display: none;
        }
    }
}

// header:hover {
//     a {
//         color: #fff;
//     }
// }
.search-active .navbar:not(.navbar-cta) {
    display: none;
}