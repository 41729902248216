.text-delimiter {
    $color: lighten($color-muted, 10%);
    z-index: 0;
    position: relative;
    color: $color;
    padding: $gutter*1.5 0;
    font-size: 1.5em;
    span {
        background: $color-real-white;
        padding: 0 $gutter/2;
    }
    &::before {
        z-index: -1;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 50%;
        height: 1px;
        background: $color;
    }
    &.text-delimiter-success {
        $color: lighten($color-succes, 10%);
        color: $color;
        span {
            background: lighten($color-succes, 40%);
        }
        &::before {
            background: $color;
        }
    }
}