.icon {
    font-size: 8rem;
    padding: $gutter;
    $list: (android: #77C159, mobile: silver, windows: #0078D7, apple: #fff);
    color: $color-muted;
    // color: $color-secondary;
    vertical-align: middle;
    @each $name, $color in $list {
         &.fa-#{$name} {
             color: $color;
            // @include neon($color, $steps: 3);
         }
    }
    &.icon-sm {
        font-size: 4rem;
        padding: $gutter/4;
    }
}
.icon-text {
    i {
        width: $gutter;
    }
}
.icon-action {
    color: $color-succes;
    @extend .cursor-pointer;
    @extend .anim;
    @extend .text-shadow;
    &:not(.no-hover):hover {
        transform: scale(1.1);
        color: darken($color-succes, 9);
    }
}
.icon-clickable {
    @extend .tap-highlight-transparent;
    user-select: none;    
}

.icon-circle {
    --ggs: 2;
    line-height: 4.6rem;
    width: 4.5rem;
    height: 4.5rem;
    color: #fff;
    font-size: 2.5rem;
    border-radius: 50%;
    margin: $gutter $gutter/2;
    margin-bottom: 0;
    border: 1px solid #fff;
    // border: 1px solid $color-transparent-white-1;
    // box-shadow: 0 0 $gutter/2 $color-transparent-white-1;
    // @extend .box-shadow-inset;
    @extend .anim;
    &:hover {
        transform: scale(1.1) translateY(-$gutter/4);
        // border: 2px solid $color-transparent-black-1;
        // @extend .animate-bounce;
    }
    @include breakpoint_max($sm) {
        line-height: 3.4rem;
        width: 3.5rem;
        height: 3.5rem;
        font-size: 2rem;
        margin-top: $gutter/2;
        background: none !important;
        color: $color-white;
        border: 2px solid $color-white;
    }
}