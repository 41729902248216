// floating
.clearfix {
	@include clearfix;
}
.d-inline-block {
	display: inline-block;
}

.position-relative {
	position: relative;
	// z-index: 1;
}

.position-absolute {
	position: absolute;
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

.z-index-back {
	z-index: -1;
}

.z-index-2 {
	z-index: 2;
}


.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-self-center {
	align-self: center;
	// align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.justify-content-space-between {
	justify-content: space-between;
}

.d-flex {
	display: flex;
}
.d-lg-flex {
	@include breakpoint_min($lg) {
		display: flex;
	}
}

// padding
.full-section {
	position: relative;
	counter-increment: sectionCounter;
	// border-top: 1px solid $color-real-white;
	// &::before {
	// 	content: "Chapter " counter(sectionCounter, upper-roman);
	// 	position: absolute;
	// 	top: $gutter;
	// 	right: $gutter;
	// 	z-index: 33;
	// 	color: $color-transparent-black-5;
	// 	@extend .font-weight-300;
	// }
	// $lg
	// @include breakpoint_min(1px) {
	// min-height: 100vh;
	// max-height: 400px;
	display: flex;
	// align-items: center;
	// align-items: flex-end;	
	.center {
		align-self: center;
	}
	.bottom {
		align-self: flex-end;
	}
	.adaptive-grid {
		flex-basis: 100%;
		flex-wrap: wrap;
	}
	// }
	@include breakpoint_min($md) {
		min-height: 100vh;
	}
}
.padding-section {
	// padding: $gutter*3 $gutter;
	padding: $gutter*3 $gutter;
	// border-top: 3px solid rgba(255, 255, 255, 0.1);
	// border-top: 3px solid rgba(0, 0, 0, 0.05);
	// ul, p {
	// 	@extend .font-alternate;
	// }
	@include breakpoint_min($md) {
		padding: $gutter*3.5 $gutter*3;
	}
	@include breakpoint_min($lg) {
		padding: $gutter*2 $gutter;
		.btn.btn-lg {
			padding-left:  $gutter;
			padding-right: $gutter;
		}
	}
	@include breakpoint_min($xl) {
		// border-top: 4px solid rgba(0, 0, 0, 0.1);
		padding: $gutter*3.5 $gutter*3;
		// h3 {
		// 	padding-bottom: $gutter/2;
		// }
		h2 {
			// margin-bottom: $gutter;
			margin-bottom: 0;
		}
		ul.list {
			margin-bottom: $gutter;
			margin-top: $gutter;
			li {
				padding-bottom: $gutter*0.6;
			}
		}
		p {
			margin-bottom: $gutter/2;
		}
		div.link-container {
			margin-top: $gutter;
		}
	}
	@include breakpoint_min($xxl) {
		padding-left: $gutter;
		padding-right: $gutter;
	}
	.lead:not(ul), h3 {
		line-height: 1.33em;
	}
	.list {
		h3 {
			font-size: 1.5rem;
			margin-bottom: $gutter/4;
			margin-top: 0;
		}
	}
	h3, .display-1, h3.lead {
		@extend .strong;
	}
	p, ul li {
		margin-top: 0;
		margin-bottom: $gutter/3;
	}
}
.padding {
	padding: $gutter;
}

.padding-left {
	padding-left: $gutter;
}

.padding-right {
	padding-right: $gutter;
}

.padding-top {
	padding-top: $gutter !important;
}

.padding-bottom {
	padding-bottom: $gutter !important;
}

.padding-sm {
	padding: $gutter/2 !important;
}

.padding-from-lg {
	@include breakpoint_min($lg) {
		padding: $gutter;
	}
}

.margin-from-lg {
	@include breakpoint_min($lg) {
		margin: $gutter;
	}
}

.padding-left-sm {
	padding-left: $gutter/2 !important;
}

.padding-right-sm {
	padding-right: $gutter/2 !important;
}

.padding-top-sm {
	padding-top: $gutter/2 !important;
}

.padding-top-xs {
	padding-top: $gutter/4 !important;
}
.padding-bottom-xs {
	padding-bottom: $gutter/4 !important;
}

.padding-bottom-sm {
	padding-bottom: $gutter/2 !important;
}

.padding-vertical {
	padding-top: $gutter !important;
	padding-bottom: $gutter !important;
}

.padding-vertical-sm {
	padding: $gutter/2 0;
}


.padding-horizontal {
	padding-left: $gutter !important;
	padding-right: $gutter !important;
}


.padding-horizontal-sm {
	padding-left: $gutter/2 !important;
	padding-right: $gutter/2 !important;
}


.no-padding {
	padding: 0 !important;
}
.no-padding-top {
	padding-top: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}


// margin
.no-margin {
	margin: 0 !important;
}

.margin {
	margin: $gutter !important;
}

.margin-sm {
	margin: $gutter/2;
}

.margin-lg {
	margin: $gutter*2 !important;
}

.margin-auto {
	margin-left: auto;
	margin-right: auto;
}

.margin-vertical {
	margin-top: $gutter !important;
	margin-bottom: $gutter !important;
}

.margin-horizontal {
	margin-left: $gutter !important;
	margin-right: $gutter !important;
}

.margin-vertical-lg {
	margin-top: $gutter*2 !important;
	margin-bottom: $gutter*2 !important;
}

.margin-top {
	margin-top: $gutter;
}

.margin-bottom {
	margin-bottom: $gutter;
}

.margin-top-small {
	margin-top: $gutter/2;
}

.margin-bottom-small {
	margin-bottom: $gutter/2;
}

.margin-left-small {
	margin-left: $gutter/2;
}

.margin-right-small {
	margin-right: $gutter/2;
}

.negative-margin {
	margin: -$gutter;
}

.negative-margin-horizontal {
	margin-left: -$gutter;
	margin-right: -$gutter;
}

.negative-margin-top {
	margin-top: -$gutter;
}

.negative-margin-bottom {
	margin-bottom: -$gutter;
}

.direction-rtl {
	direction: rtl;
}

// line height

// text transformation & centering
.text-uppercase {
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.text-nowrap {
	white-space: nowrap;
	overflow: hidden;
	// text-overflow: ellipsis;
	// direction: rtl; 
	// position: relative;
	// margin-right: -0.6px; // fix for a text that's exacty in limit.
}
.blur-backdrop {
	// background: $color-transparent-black-9;
	// background: $color-transparent-black-7;
	background: darken($color-overlay, 3%);
	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		background-color: transparent;
		-webkit-backdrop-filter: blur($gutter/2);
		backdrop-filter: blur($gutter/2);
	}
}
.text-ellipsis {
	text-overflow: ellipsis;
	@extend .text-nowrap;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-sm {
	font-size: 0.8em;
}

.text-xs {
	font-size: 0.7em;
}
.text-md {
	font-size: 1.1em;
}

.text-lg {
	font-size: 1.3em;
}

.text-xlg {
	font-size: 2em;
}

.display-1 {
	// font-size: 1.8rem;
	// font-size: 2.2rem;
	font-size: 2.4rem;
	color: #000;
	padding: $gutter/6 0;
	margin-top: 0;
	margin-bottom: $gutter;
	@extend .text-shadow-subtle;
	// position: relative;
	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	top: 60px;
	// 	border-radius: $border-radius;

	// 	width: 30%;
	// 	height: 5px;
	// 	background: $color-transparent-black-1;
	// }

	@include breakpoint_min($md) {
		font-size: 2.8rem;
		line-height: 1.1;
	}
	// font-size: 3rem;
	
	// font-weight: 400;
	//  background: linear-gradient(to bottom, $color-primary, $color-succes);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// @extend .font-distinct;
}
.lead, .lead h3 {
	font-size: 1.2rem;
	line-height: 1.2;
	@include breakpoint_min($md) {
		font-size: 1.4rem;
	}
}

// text color
.text-primary {
	color: $color-primary !important;
}

.text-secondary {
	color: $color-secondary;
}

.text-accent {
	color: $color-accent;
}

.text-success {
	color: $color-succes !important;
}

.text-error {
	color: $color-error !important;
}

.text-neutral {
	color: $color-neutral !important;
}

.text-muted {
	color: $color-muted  !important;
}

.text-white {
	color: $color-white !important;
}

.text-black-5 {
	color: $color-transparent-black-5 !important;
}

.text-black {
	color: $color-black !important;
}

.text-body {
	color: $color-text !important;
}

// border color
.border {
	border: 1px solid $color-primary;
	&.border-neutral {
		border-color: $color-neutral;
	}
}
.border-muted {
	border: 1px solid $color-muted;
}
.border-white {
	border: 1px solid #fff;
}
.border-light {
	border: 1px solid darken($color-light, 5%); /** @inputoverlay **/
}
.border-success {
	border: 1px solid $color-succes;
}
.border-primary {
	border: 1px solid $color-primary;
}
.border-secondary {
	border: 1px solid $color-secondary;
}

.border-top {
	border-top: 1px solid $color-primary;
	&.border-top-neutral {
		border-color: $color-neutral;
	}
}
.border-transparent {
	border: 1px solid $color-transparent-white-3;
	border: 1px solid $color-transparent-black-9;
}
.border-transparent-white {
	border: 1px solid $color-transparent-white-3;
}

.border-top-transparent {
	// border-top: 1px solid $color-transparent-white-3;
	border-top: 1px solid $color-transparent-black-1;
}

.border-top-transparent-white {
	border-top: 1px solid $color-transparent-white-1;
}

.border-bottom {
	border-bottom: 1px solid $color-primary;
}

.border-bottom-transparent {
	border-bottom: 1px solid $color-transparent-black-9;
}

.border-bottom-transparent-white {
	border-bottom: 1px solid $color-transparent-white-1;
}

// border radius
.border-radius {
	border-radius: $border-radius;
}
.border-radius-bottom {
	border-radius: 0 0 $border-radius $border-radius;
}

// delimiter
.delimiter {
	background: $color-neutral;
	height: 1px;
	margin: $gutter 0;
	&.delimiter-muted {
		background: $color-muted;
	}
	&.delimiter-success {
		background: $color-succes;
	}
}

// font 
.font-base {
	// font-family: Roboto, "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
	font-weight: 300;
	font-family: 'Rubik', sans-serif;
	// font-family: 'DM Sans', sans-serif;
}
.font-distinct {
	// font-family: Roboto, "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
	font-weight: 100;
	font-family: 'Rubik', sans-serif;
	// font-family: 'DM Sans', sans-serif;
}
.font-alternate {
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
}
.font-user-content {
	//	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
	// line-height: 1.6em;
	// font-family: 'Lora', sans-serif;
	// font-family: 'Montserrat', sans-serif;
	// font-family: 'Open Sans', sans-serif;
	// font-family: 'Raleway', sans-serif;
	// line-height: 1.4em;
	// removed //fonts.googleapis.com/css?family=Roboto:100,300,400|Open+Sans
	font-family: inherit;
	// line-break: anywhere;
	word-break: break-word;
	overflow-wrap: break-word;
}
.font-weight-500, .strong {
	font-weight: 500;
}
.font-weight-700, .stronger {
	font-weight: 700;
}

.font-weight-300 {
	font-weight: 300;
}

// appeareance filters
.bg-primary {
	color: #fff;
	background: $color-primary;
}
.bg-muted {
	background: $color-muted;
}
.bg-neutral {
	background: $color-light;
}
.bg-white {
	background: #fff !important;
	color: $color-text;
}
.bg-light {
	$md: 0;
	//background: #eff3f7 !important;
	@include breakpoint_min($md) {
		background: $color-light !important;
		// background: lighten($color-secondary, 55%) !important;
	}
}

.text-gradient-light {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.4), $color-real-white);
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.text-gradient-dark {
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.bg-sunset {
	background: linear-gradient(to bottom, #a2bdde, #ccc9c4);
}
.bg-dare {
	$color: #465597; 
	// $color: #8e4697; 
	// $color: #467d97; 
	$color-text: $color-transparent-black-6; // 35
	$color-text-head: darken($color, 0%);
	background: lighten($color, 50%);
	ul {
		@include breakpoint_min($xl) {
			position: relative;
			z-index: 1;
			padding-top: $gutter;
			// margin-bottom: $gutter*1.5 !important;
			&:before {
				z-index: -1;
				content: '';
				position: absolute;
				// z-index: -1;
				top: 0;
				bottom: 0;
				left: -$gutter;
				right: -$gutter*5;
				// background: linear-gradient(to right, transparent, $color-light);
				background: linear-gradient(to right, lighten($color, 50%), $color-light); // safari fix, it seems it doesnt like transparent.
			}
		}
		li i {
			color: $color-text-head !important;
		}
	}
	button, .btn {
		color: $color-text-head !important;
		&.btn-primary {
			background:  $color-text-head;
			color: #fff !important;
			&:hover {
				background: darken($color-text-head, 9%);
			}
		}
		&.btn-outline-primary {
			border-color: $color-text-head;
			&:hover {
				color: #fff  !important;
				background:  $color-text-head;
			}
		}
	}
	.display-1, h3.lead {
		// color: transparent;
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
	// border-top: 4px solid rgba(0, 0, 0, 0.1);
}
.bg-creativity {
	// z-index: 3;
	position: relative;
	$color: rgb(255, 130, 30);
	$color: rgb(10, 200, 20);
	$color: #a66fff;
	$color: #bb3e48;
	$color: rgb(127, 90, 131); 
	$color-text: $color-transparent-black-6; // 35
	$color-text-head: darken($color, 0%);
	
	// background-image: linear-gradient(45deg, #fff, $color);
	background: lighten($color, 50%);
	// color: $color-text;
	ul li i {
		color: $color-text-head !important;
	}
	.display-1, h3 {
		// color: transparent;
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
	// border-top: 4px solid rgba(0, 0, 0, 0.1);
	// border-top: 4px solid rgba(0, 0, 0, 0.05);
}
.bg-confidence {
	$color: #82d3ff;
	$color-text: $color-transparent-black-7;
	$color-text-head: darken($color, 30%);
	
	// background: linear-gradient(45deg, $color, #fff);
	background: lighten($color, 20%);
	// color: $color-text;
	// .text {
	// 	color: $color-text;
	// }
	ul li {
		i {
			color: $color-text-head !important;
		}
	}

	button, .btn {
		color: $color-text-head !important;
		&.btn-primary {
			background:  $color-text-head;
			color: #fff !important;
			&:hover {
				background: darken($color-text-head, 9%);
			}
		}
		&.btn-outline-primary {
			border-color: $color-text-head;
			&:hover {
				color: #fff  !important;
				background:  $color-text-head;
			}
		}
	}
	.display-1, h3.lead, .head {
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
	// border-top: 4px solid rgba(0, 0, 0, 0.1);
}
.bg-confidence-2 {
	// $color: lighten(#82d3ff, 10%);
	// background: linear-gradient(to bottom, $color, lighten($color, 5%));
	background-image: none;
}
.bg-trust {
	$color: #39CCCC;
	$color-text: $color-transparent-black-7;
	$color-text-head: darken($color, 15%);
	background: #fff;
	// background: radial-gradient(circle at top right, lighten($color, 30%) 25%, lighten($color, 35%) 25% 35%, #fff 35%);
	// background: linear-gradient(to left, lighten($color, 40%) 52%, #fff 35%);

	position: relative;
	.display-1, h3 {
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
	.front {
		z-index: 2;
	}

	button, .btn {
		color: $color-text-head !important;
		&.btn-primary {
			background:  $color-text-head;
			color: #fff !important;
			&:hover {
				background: darken($color-text-head, 9%);
			}
		}
		&.btn-outline-primary {
			border-color: $color-text-head;
			&:hover {
				color: #fff  !important;
				background:  $color-text-head;
			}
		}
	}
	// border-top: 4px solid rgba(0, 0, 0, 0.1);
}
.bg-alert {
	z-index: 4; // because of parallax phones
	$color: rgb(255, 192, 241);
	$color: #dbbbeb;
	$color: rgb(253, 166, 163);
	$color: rgb(206, 71, 47);

	$color-text: $color-transparent-black-7;
	$color-text-head: darken($color, 0%);

	//calc(50% + #{$gutter * 5})
	background: linear-gradient(to bottom, $color, lighten($color, 15%));
	background: lighten($color, 48%);
	.display-1, h3 {
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
	button, .btn {
		&.btn-primary {
			background:  $color-text-head;
			color: #fff !important;
			&:hover {
				background: darken($color-text-head, 9%);
			}
		}
		&.btn-outline-primary {
			color: $color-text-head !important;
			border-color: $color-text-head;
			&:hover {
				color: #fff  !important;
				background:  $color-text-head;
			}
		}
	}
	// border-top: 4px solid rgba(0, 0, 0, 0.1);
	// border-top: 4px solid rgba(0, 0, 0, 0.05);
}

.bg-sobre {
	$color: #ccc9c4;
	$color-text: $color-transparent-black-7;
	$color-text-head: darken($color, 35%);
	
	// color: $color-text;
	background: linear-gradient(-45deg, $color, #fff);
	background: lighten($color, 20%);
	.display-1, h3 {
		color: $color-text-head;
		// @extend .text-gradient-dark;
	}
}

.bg-facebook {
	background: $color-facebook;
}
.bg-twitter {
	background: $color-twitter;
}
.bg-producthunt {
	background: $color-producthunt;
}
.transparent {
	background: transparent; /** !important **/
}
.bg-blur {
	// background: #000;
	box-shadow: 0 0 2px inset $color-transparent-white-1;
	position: relative;
	z-index: 1;
	@extend .blur-backdrop;
}

.bg-base {
	background: $color-background-base !important;

}
.bg-overlay-darker {
	background: darken($color-background-base, 25%);
}

.bg-transparent {
	// background: $color-transparent-black-9 !important;
	background: rgba($color-primary, 0.1);
}
.bg-transparent-main {
	background: rgba($main-bg, .9);
}
.bg-transparent-light {
	background: $color-transparent-real-black-3 !important;
}
.bg-transparent-dark {
	background: $color-transparent-real-black-9 !important;
}
.bg-transparent-white-light {
	background:  rgba(255, 255, 255, 0.05) !important;
	// background:  rgba(255, 255, 255, 0.15) !important;
}
.bg-transparent-white-light-gradient {
	@include breakpoint_min($lg) {
		background: linear-gradient(to right, rgba(255, 255, 255, 0.05), transparent) !important;
		box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.01);
	}
	// color: darken($color-real-white, 0%) !important;
}
.bg-primary-transparent {
	// background: rgba(108, 105, 147, 0.3);
	background: rgba($color-primary, 0.3);
}
.bg-transparent-white {
	color: $color-text;
	background-color: rgba(255, 255, 255, 0.95);
}
.blur {
	filter: blur(10px);
	transition: all $transition-timing;
}
.blur-light {
	filter: blur(1px);
	transition: filter $transition-timing;
}

.overlay-layer {
	&:before {
		content: '';
		background: $color-transparent-black-5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: fixed;
	}
}

.opacity-0 {
	opacity: 0;
	// will-change: transform;
}

.opacity-01 {
	opacity: 0.1;
}

.opacity-05 {
	opacity: 0.5;
}

.opacity-07 {
	opacity: 0.7;
}

.opacity-09 {
	opacity: 0.9;
}


.opacity-1 {
	opacity: 1;
}

.box-shadow-inset {
	box-shadow: 0 0 1px inset red;
}

.box-shadow {
	@include box-shadow();
}

.box-shadow-light {
	@include box-shadow($length: 5px);
}

.box-shadow-spread {
	@include box-shadow($color: $color-transparent-black-1, $length: $gutter/2, $y: $gutter/2);
}

.box-shadow-white {
	@include box-shadow($color: $color-white, $length: 5px);
}

.box-shadow-blend {
	// @include breakpoint_max($md) {
		// box-shadow: 0 -4px $gutter*1 2px var(--color);
		box-shadow: 0 -4px $gutter var(--color);
		// box-shadow: 0 $gutter/2 $gutter*2 2px var(--color);
	// }
}

.text-shadow {
	@include text-shadow();
}

.text-shadow-subtle {
	text-shadow: rgba(0,0,0,0.1) 1px 1px 4px;
}

.overlay-bg-white-fix {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		// background: rgba(0,0,0,.05);
		background: rgba($color-background-base,.05);
	}
}

.no-anim {
	transition: none !important;
}

// transiitions (why did i put anim name?)
.anim {
	transition: all $transition-timing $transition-timing-function;
}
.anim-half {
	transition: all $transition-timing/2 $transition-timing-function;
}
.anim-2x {
	transition: all $transition-timing*2 $transition-timing-function;
}

// animations
.animate-reverse {
	animation-direction: reverse !important;
}

// it has to be same configration as .fadein-enter, .fadein-appear
.animate-fade-in-scale {
	transform-origin: 50% 50%;
	@include animation(fade-in-scale, $time: 1, $duration: $animation-timing);
}
.animate-fade-in-scale-faster {
	transform-origin: 50% 50%;
	@include animation(fade-in-scale, $time: 1, $duration: $animation-timing/2);
}

.animate-fade-in-scale-slowly {
	transform-origin: 50% 50%;
	@include animation(scale, $time: 1, $duration: $animation-timing*1.5);
}

.animate-translate-in {
	@include animation(translate-in, $time: 1, $duration: $animation-timing);
}

.animate-fade-in {
	@include animation(fade-in, $time: 1, $duration: $animation-timing);
}
.animate-fade-in-simple {
	@include animation(fade-in-simple, $time: 1, $duration: $animation-timing);
}
.animate-fade-out {
	@include animation(fade-out, $time: 1, $duration: $animation-timing);
}

.animate-fade-in-negative {
	@include animation(fade-in-negative, $time: 1, $duration: $animation-timing);
}

.animate-fade-in-x {
	@include animation(fade-in-x, $time: 1, $duration: $animation-timing);
}

.animate-rotate {
	@include animation(rotate, $duration: 2s);
}

.animate-rotate-full-circle {
	@include animation(rotate-full-circle, $duration: 2s);
}

.animate-rotate-side {
	@include animation(rotate-side, $duration: 2s);
}


.animate-pulsate {
	@include animation(pulsate, $duration: 1.5s);
}

.animate-glitch {
	@include animation(glitch, $duration: $animation-timing*10);
}

.animate-scale {
	@include animation(scale, $time: 1, $duration: $animation-timing);
}

.animate-scale-in {
	@include animation(scale-in, $time: 1, $duration: $animation-timing * 2);
}

.animate-scale-y {
	@include animation(scaleY, $time: 1, $duration: $animation-timing);
	@include breakpoint_min($lg) {
        animation-name: scaleYnoglichgh;
      }
}

.animate-translate-left {
	@include animation(translate-left, $time: 1, $duration: $animation-timing);
}

.animate-bounce {
	@include animation(bounce, $duration: 1.5s, $fill-mode: both);
}

.animate-forwards {
	animation-fill-mode: both;
}

.animate-once, .animation-iterations-1 {
	animation-iteration-count: 1;
}

.animation-iterations-2 {
	animation-iteration-count: 2;
}
.animation-iterations-3 {
	animation-iteration-count: 3;
}

.animation-duration-slow {
	animation-duration: $animation-timing*1.5;
}
.animation-duration-fast {
	animation-duration: $animation-timing/2;
}
.transform-origin-x-50 {
	transform-origin: 50% 0%;
}
.transform-origin-y-50 {
	transform-origin: 0% 50%;
}

// used before updating to Framer Motion for orchestrating animations.
@mixin cascade-animation-delay($parent, $delay, $delay-add: $animation-timing/2, $depth: 3) {
	#{$parent} {
		@while ($depth > 0)  {
			// div[class*="animate-delay"]
			$depth: $depth - 1;
			@include cascade-animation-delay('.animate-chain', $delay + $delay-add, $delay-add, $depth);
				@if ($depth == 0) {
					animation-delay: $delay !important;
			}
		}
	}
}

// animation delay
$delay-list: 0 2 3 5 6 7 10 20 30 50;

@each $delay in $delay-list {
	$delay-second: 0s + $delay / 10;
	@include cascade-animation-delay('.animate-delay-#{$delay}', $delay-second, $depth: 1);
}

 .animate-fast {
	 animation-duration: 1s;
 }

 .img-fluid {
	 max-width: 100%;
 }

// cursors
.cursor-pointer, .action {
	cursor: pointer;
}

.cursor-help {
	cursor: help;
}

.cursor-disabled {
	cursor: not-allowed !important;
}

// tap events
.tap-highlight-transparent {
	-webkit-tap-highlight-color: transparent;
}

// shapes
.circle {
	@include circle($bg-color-primary);
}

.tag {
	@include tag($bg-color-primary);
}

// dimensions
.h-100 {
	height: 100%;
}
.wide, w-100 {
	width: 100%;
}
.wide-80 {
	width: 80%;
}
.wide-90 {
	width: 90%;
}
.max-width {
	max-width: 36em;
	// margin-left: auto;
	// margin-right: auto;
}
.to-0  {
	transform: scale(0);
	margin-left: -240px !important;
	// transform-origin: top;	
	//width: 0 !important;
	//margin-left: -200px !important;
	opacity: 0;
	pointer-events: none;
	// width: 10px !important;
	// font-size: 0 !important;
	// height: 10px !important;
	// padding: 0 !important;
	// pointer-events: none;
	// opacity: 0;
	
	@extend .anim-2x;
}

// appearenace
.hide, .d-none {
	display: none;
}
.visible-lg {
	@include breakpoint_max($lg) {
		display: none !important;
	}
}
.visible-xl {
	@include breakpoint_max($xl) {
		display: none !important;
	}
}
.visible-md {
	@include breakpoint_max($md) {
		display: none !important;
	}
}
.hide-md {
	@include breakpoint_min($md) {
		display: none !important;
	}
}
.hide-lg {
	@include breakpoint_min($lg) {
		display: none !important;
	}
}

// misc
.legend {
	background: rgba(0, 0, 0, .2);
	// @extend .bg-transparent-light;
	// @extend .bg-transparent;
	@extend .padding-sm;
	@extend .border-radius;
	// a {
	// 	color: $color-secondary-transparent;
	// }
}
// all actions will be disabled above children
.disable-actions {
	pointer-events: none !important;
	*:not(header):not(.phone.add) {
		transition: none !important;
	}
}
.disable-enable-actions {
	pointer-events: none;
	* {
		pointer-events: all;
	}
}

.overflow-hidden {
	overflow: hidden;
}

// @temp collapsable only on mobile rite now. used esp for new phone.
@include breakpoint_max(313131px) {
	.collapsable {
		max-height: 600px;
		overflow: hidden;
		opacity: 1;
		transition: opacity $transition-timing*2 $transition-timing-function;
	}
	.collapse {
		max-height: 0;
		padding: 0 !important;
		margin: 0 !important;
		opacity: 0;
	}
}

  // @idea https://stackoverflow.com/a/49968820/1894856
  .offset {
	position: absolute;
	top: -$gutter*3;
}

.clip-bottom-right {
	clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);
	@include breakpoint_min($md) {
		clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
	}
}
.clip-top-right {
	clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
}

.cta-appear-centered {
	--color: #{$color-transparent-black-3};
	z-index: 2;
	@include breakpoint_min($xl) {
		margin-left: $gutter*2;
	}
	.cta {
		opacity: 0;
		position: absolute;
		// top: 50%;
		top: 50%;
		left: 50%;
		z-index: 99;
		transform: translate(-50%, -50%);
		
		@extend .border-radius;
		@extend .anim;
	}
	img {
		object-fit: cover;
		object-position: top left;
		height: 100%; // fix for safari
	}
	p {
		position: relative;
		max-width: 380px;
		margin-left: auto;
		margin-right: auto;
		&:after {
			content: '';
			position: absolute;
			background: $color-accent;
			top: 0;
			height: 4px;
			left: 0;
			right: 0;
			width: $gutter*3;
			margin: auto;
			border-radius: $border-radius;
		}
	}
	&:hover {
		.cta {
			opacity: 1;
		}
	}
}