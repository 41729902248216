.menu {
    $color-muted: $color-white;
    $base-metric: 6px;
    $height: $base-metric/3;
    $height: $base-metric/3;
    $width: $base-metric*4;
    $margin: $base-metric/3;
    display: block;
    // $transition-timing: $transition-timing;
    // padding: $gutter;
    @extend .font-distinct;
    
    &.active {
        // @extend .blur-backdrop;
        .initiator {
            // *, :nth-child(3) {
            //     height: $height;
            //     width: $width;
            //     border-radius: 0;
            //  }
            * {
                // background: $color-error !important;
                background: $color-accent !important;
            }
            :nth-child(odd) {
                opacity: 0;
                
            }
            :nth-child(even) {
                opacity: 1;
                // transition-delay: $transition-timing*0.5;
                transition-delay: $transition-timing*0.5;
            }
            :nth-child(3) {
                // transition-delay: $transition-timing*0.25;
                transition-delay: 0;
            }
            :first-child {
                transform: translateY($margin + $height);
            }
            :last-child {
                transform: translateY(- $margin - $height);
            }
            :nth-child(2) {
                transform: translateY($margin + $height) rotate(45deg);
            }
            :nth-child(4) {
                transform: translateY(-$margin - $height) rotate(-45deg);  
            }
        }
        .data {
            // z-index: 4;
            // @include transition-translate(X, true);
            padding-top: $gutter/4;
            padding-bottom: $gutter;
            // overflow: auto;
            max-height: 500px;
            pointer-events: auto;
            transition-delay: $transition-timing/2; // @enter
            // &:before {
            //     width: $gutter*20;
            //     background: $color-white;
            //     opacity: 1;
            // }
            
            @include breakpoint_min($md) {
                padding: $gutter 0;
            }

            ul {
                li {
                    @include transition-translate(Y, true);
                    @include transition-delay(1, 5, 0.25, $initialDelay: $transition-timing);
                }
            }
        }
    }
    // aka hamburger
    .initiator {
        position: relative;
        cursor: pointer;
        vertical-align: middle;
        margin-right: $gutter/2;
        @extend .d-inline-block;
        @extend .tap-highlight-transparent;
        @include breakpoint_min($md) {
            margin-right: $gutter;
        }
        &:hover {
            * {
                background: $color-real-white;
            }
        }
        * {
            // border-radius: $border-radius;
            display: block;
            // background: #fff;
            background: $color-secondary;
            background: $color-muted;
            width: $width;
            height: $height;
            // border-radius: 50%; // muhahaha
            margin: $margin 0;
            transition-duration: $transition-timing;
            @extend .anim-half;
            @extend .border-radius;
        }
        :nth-child(odd) {
            opacity: 1;
            // opacity: 0;
        }
        :nth-child(even) {
            opacity: 0;
        }
        // :nth-child(3) {
        //     opacity: 1;
        //     height: $gutter/2;
        //     width: $gutter/2;
        //     border-radius: 50%;
        // }
        :nth-child(2) {
           transform: translateY($margin + $height);
        }
        :nth-child(4) {
            transform: translateY(-$margin - $height); 
        }
    }
    .data {
        position: relative;
        overflow: hidden;
        max-height: 0;
        pointer-events: none;
        width: 100%;
        padding:  0;
        // padding-top: $gutter/2;
        line-height: 2.4rem;
        font-size: 1rem;
        color: #fff;

        //@extend .pattern;
        // @extend .anim;
        transition: max-height $transition-timing $transition-timing-function 0s, 
                padding $transition-timing $transition-timing-function 0s;
        transform: translate3d(0);
        transition-delay: $transition-timing/2;; // @exit
        // will-change: max-height, padding;

        // &:before {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 0;
        //     height: 4px;
        //     opacity: 0;
        //     transition-delay: $transition-timing;
        //     @extend .anim;
        //     @extend .border-radius;
        // }
        // @include transition-translate(Y, $distance: -$gutter/4);
 
        @include breakpoint_max($sm) {
            font-size: 0.82rem;
            //@include transition-translate(X);
        }
        
        div {
            @extend .pull-left;
            &:nth-child(1) {
                padding-right: $gutter;
            }
            @include breakpoint_min($md) {
                &:not(:last-child) {
                    padding-right: $gutter*1.5;
                }      
            }
        }
        p, a, span {
            opacity: 1;
        }
        p {
            font-size: 2.1rem;
            padding: 0;
            margin-bottom: $gutter/2;
            color: $color-real-white;
            // color: $color-secondary;
            text-transform: capitalize;
            @include breakpoint_max($md) {
                &:first-of-type {
                    margin-top: $gutter;
                }
            }
            // &::first-letter {
            //     font-weight: 300;
            // }
            a {
                color: $color-white;
                padding: 0 $gutter/2;
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                @include transition-translate(Y);
                // transition-delay: $transition-timing*3; // @exit // @edit: or no delay.
                a {
                    // position: relative;
                    font-size: 1.4em;
                    color: $color-white;
                    &:hover {
                        color: #fff;
                    }
                    /*
                    &::after {
                        content: '';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        left: 0;
                        right: 0;
                        bottom: -1px;
                        background: #fff;
                        transform: scale(0);
                        opacity: 0;
                        @extend .anim-half;
                    }
                    &:hover {
                        &::after {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    */
                }
                p {
                    display: none; // @todo
                    font-size: 0.8em;
                    line-height: 0;
                    text-transform: none;
                    @extend .font-weight-500;
                    @extend .text-success;
                }
            }
        }
        i {
            color: $color-transparent-white-7;
            color: $color-white;
            // font-size: 2rem;
            // padding-right: $gutter/2;
            padding: 0 $gutter/4;
            &:hover {
                color: #fff;
            }
            position: relative;
        }
    }
}
.sticky-or-not {
    .menu {
        .initiator > span {
            background: $color-primary;
            background: $color-secondary;
        }
        &.active {
            .data {
                // @include transition-translate(Y, true);
                // padding: $gutter !important;
            }
        }
        .data {
            // top: $gutter*3;
            // max-width: 100%;
            // transform: none;
            // @include transition-translate($axis: Y);
        }
    }
}


// header.sticky {
//     .menu {
//         p {
//             color: $color-primary;
//         }
//     }
// }

header:not(.active):not(.sticky) {
    .data {
        p, a, span {
            opacity: 0.1;
            color: #fff !important;
            @extend .anim;
        }
    }
}