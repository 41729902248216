@mixin breakpoint_min($breakpoint) {
    @if ($breakpoint > 0) {
        @media (min-width: #{$breakpoint}){
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin breakpoint_max($breakpoint) {
    // it's -1 because we dont want to collapse with breakpoint_min.
    @media (max-width: #{$breakpoint - 1}){
        @content;
    }
}


@mixin breakpoint_height_max($breakpoint) {
    // it's -1 because we dont want to collapse with breakpoint_min.
    @media (max-height: #{$breakpoint - 1}){
        @content;
    }
}