.link {
    &:hover {
        text-decoration: underline;
    }
}
/**
.link {
    position: relative;
    z-index: 0;
    display: inline-block;
    @extend .anim-half;
    &:after {
            position: absolute;
            top: -$gutter/2;
            bottom: -$gutter/2;
            left: -$gutter/2;
            right: -$gutter/2;
            z-index: -1;
            // height: 100%;
            border: 1px solid #fff;
            content: '';
            opacity: 0;
            transform: scale(0);
            @extend .anim-half;
    }
    &:hover {
        transform: scale(0.95);
        text-decoration: none;
        &:after {
            opacity: 1;
            transform: scale(1);
        }
    }
}
**/