.user {
    position: relative;
    z-index: 1;
    text-align: left;
    font-size: 1rem;
    display: inline-block;
    a {
        img:not(.xl) {
            @extend .anim;
        }
    }
    img {
        border-radius: 50%;
        $base-metric: $gutter*2;
        width: $base-metric;
        height: $base-metric;
        min-width: $base-metric;
        min-height: $base-metric;
        max-width: $base-metric;
        max-height: $base-metric;
        vertical-align: middle;
        background: 0% 0% / cover;
        // background-color: $color-transparent-black-1; 
        background-color: $color-muted; 
        background-color: $color-overlay; 
        background-image: url('~assets/img/blue/logo-white.png');
        // background-origin: content-box;
        background-repeat: no-repeat;
        // border: 2px solid #fff;
        //border: 4px solid $color-transparent-white-3;
        //border: 2px solid $color-primary;
        // @extend .box-shadow-inset;
        &.sm {
            $base-metric: $gutter*1.5;
            width: $base-metric;
            height: $base-metric;
            min-width: $base-metric;
            min-height: $base-metric;
            max-width: $base-metric;
            max-height: $base-metric;
        }
        &.md {
            $base-metric: $gutter*1.8;
            width: $base-metric;
            height: $base-metric;
            min-width: $base-metric;
            min-height: $base-metric;
            max-width: $base-metric;
            max-height: $base-metric;
        }
        &.lg {
            $base-metric: $gutter*4;
            width: $base-metric;
            height: $base-metric;
            min-width: $base-metric;
            min-height: $base-metric;
            max-width: $base-metric;
            max-height: $base-metric;
        }
        &.xl {
            $base-metric: $gutter*6;
            width: $base-metric;
            height: $base-metric;
            min-width: $base-metric;
            min-height: $base-metric;
            max-width: $base-metric;
            max-height: $base-metric;
        }
        
        // nah, it looks better without shaadows.
        // @extend .box-shadow-light;
    }
    span {
        padding-left: $gutter/2;
    }
    &.centered {
        text-align: center;
    }
    &.spotlight {
        display: block;
        img {
            width: $gutter*4;
            height: $gutter*4;
            max-width: $gutter*4;
            max-height: $gutter*4;
            margin: $gutter/2 0;
            margin-bottom: $gutter/4;
            border-width: 2px !important;
        }
        span {
            display: block;
            font-size: 0.9em;
        }
        ul {
            left: 50%;
        }
    }
    &:hover {
        // @fix disappearing menu <crap workaroung>
        // so we create a transparent:before, that creates more space
        // so the menu will not disaappear when moving diagonally (where normally is a gap between)
        // using before and after because we also have the search trigger, and we dont want that to be affected
        &:before, &:after {
            content: "";
            position: absolute;
            z-index: -1; // so the avatar will be clickable
            //  min-width: 15em;
            height: 100%;
            width: $gutter*2.5;
            right: 0;
            bottom: -$gutter/1;
            // background: red;
            background: transparent;
            display: inline-block; 
        }
        &:after {
            width: 15em; 
            // background: blue;
            height: $gutter*1.5;
        }
        ul:not(.closed) {
            pointer-events: auto;
            @include transition-translate(Y, true);
            li.deactivated {
                @include transition-translate(Y, true);
                transition: transform $transition-timing $transition-timing-function; /** overwrite **/
            }
        }
    }
    ul {
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: calc(50% + #{$gutter*1.4} + 2px);
        left: 0;
        // min-width: 15em;
        // min-width: 12em; // @roboto
        min-width: 16em; 
        padding: 0;
        font-size: 0.95rem;
        background: $color-transparent-black-9;
        border-radius: $border-radius;
        @extend .box-shadow;
        @extend .strong;
        @include transition-translate(Y, false, $gutter/4);
        &.closed {
            transition: none;
        }
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: -$gutter/1.5;
            left: $gutter/2+$gutter/6;
            border-bottom: $gutter/3 solid $color-transparent-black-9;
            @each $direction in top left right {
                border-#{$direction}: $gutter/3 solid transparent;
            }
        }
        
        li {
            position: relative;
            padding: 0;
            list-style: none;
            overflow: hidden;
            // @include transition-translate(Y, false, $gutter/4);
            &:first-child {
                border-radius: $border-radius $border-radius 0 0;
            }
            &:last-child {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
            &:hover, &:active {
                background: $color-white;
                background: #fff;
            }
            i {
                width: $gutter;
            }
            a {
                color: $color-white;
                color: #fff;
                display: block;
                line-height: 1.5em;
                padding: $gutter/1.5 $gutter;
                &:hover {
                    color: $color-text;
                }
                // border-bottom: 1px solid lighten($color-secondary, 35%);
            }
        }
    }
}

header {
    .user {
        @extend .pull-right;
        img {
            // border: none;
            border: 2px solid #fff;
            box-sizing: content-box;
            // box-shadow: 0px 0px 6px 1px rgba(255, 255, 255, 0.3);
        }
        // @todo @quickfix FIX THIS PROPERY.
        ul {
            &::after {
                border-bottom-color: #fff;
            }
            background: #fff;
            &::after, &::before  {
                right: $gutter/2+$gutter/6;
                left: auto;
            }
            right: 0;
            margin-top: $gutter/4;
            left: auto;
        }
        li {
            $color-secondary: #333;
            $color-secondary: $color-primary;
            a {
                color: $color-secondary;
                &:hover {
                    background: rgba($color-secondary, 0.1);
                    color: $color-secondary;;
                }
            }
        }
    }
}

.transparent {
    .user {
        img {
            border: 1px solid $color-white; // $color-muted
            &.xl {
                border-width: 2px;
                box-shadow: 0 0 12px $color-white;
            }
        }
    }
}

.tabpane, .review {
    .user {
        img {
            border: none;
        }
    }
}

.user-grid {
    .user {
        z-index: auto;
        &:hover {
            img {
                transform: scale($scale-factor);
            }
        }
        // margin: $gutter/4;
        &:not(:first-of-type) {
            margin-left: -$gutter/1.2;
        }
        a {
            display: inline-flex;
        }
        img {
          background: $color-overlay;  
          border: 1px solid $color-background-base;
        }
    }
}

.user-preference {
    position: relative;
    &:before, &:after {
        position: absolute;
        left: 0;
        right: 0;
        // z-index: 2;
        // color: $color-transparent-black-1;
        opacity: 0;
        @extend .text-sm;
        @extend .text-nowrap;
        @extend .text-center;
        @extend .anim;
    }
    &:before {
        content: attr(data-dislike-count) ' dislikes';
        bottom: 0;   
        // color: var(--color-success);
    }
    &:after {
        content: attr(data-like-count) ' likes';
        top: 0;
        // color: var(--color-error);
    }
    &:hover, &.active {
        &:before, &:after {
            color: $color-white;
            opacity: 1;
        }
        &:before {
            transform: translateY($gutter*1.3/2);    
        }
        &:after {
            transform: translateY(-$gutter*1.3/2);
        }
        > div {
            transform: scale(1.2) rotateZ(90deg);
            &[style]{
                --percentage-use: var(--percent-normalized);
            }
        }
        .user a img {
            transform: scale(0.9);
            // @extend .box-shadow;
        }
    }
    > div {
        content: '';
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        background: $color-white;
        top: -$gutter/6;
        bottom: -$gutter/6;
        left: -$gutter/6;
        right: -$gutter/6;
        // background: linear-gradient(to right, $color-succes 50%, $color-error 100%);
        // background: radial-gradient($color-succes 50%, $color-error 100%);
        @extend .anim;    
        &[style]{
            // background: linear-gradient(to right, var(--color-success) var(--percentage, 0%), var(--color-error) 0%);
            // background: linear-gradient(90deg, transparent var(--percentage, 0%), var(--color-error) 0%), 
            //     linear-gradient(100deg, transparent var(--percentage, 0%), var(--color-error) 0%), 
            //     linear-gradient(80deg, var(--color-success) var(--percentage, 0%), var(--color-error) 0%); 
            // --degree-left: calc(180deg - var(--percent) * 1deg); // initail: 140deg

            /**
                Degree left:
                - Initially we want a 90deg angle. 
                - We start from the middle (50) and substract current percent and weight it 0.3.

                Degree right
                - It's the oposite of degree left but we want it rotated, thus adding another 180deg.

                The rest is story :D
                **/
            --percentage-use: var(--percent-real);
            --percentage: calc(var(--percentage-use) * 1%); // add % to percent variable
            --degree-left: calc(90deg + (50 - var(--percentage-use)) * 0.3deg);
            --degree-right: calc(var(--degree-left) * -1 + 180deg); // reverse degree and add 180deg for rotation.
            // combine 2 backgrounds, one 50%, one 50% with position 100%.
            // this way we can create convex shape on both sides (top / bottom) based on percent.
            // add also +1% for smooth edge.
            background: linear-gradient(var(--degree-left), var(--color-success) var(--percentage, 0%), var(--color-error)  calc(var(--percentage) + 1%)),
                linear-gradient(var(--degree-right), var(--color-success) var(--percentage, 0%), var(--color-error) calc(var(--percentage) + 1%));
            background-size: 100% 50.18%;  // because on some resolutions & mobile there is a gap between backgrounds.
            background-repeat: no-repeat;
            background-position-y: 100%, 0%;       
        }
    }
    // in the end, it looks better if we have a background over img.
    > div:not([style]) + span img, img {
        // background-color: $color-transparent-black-9;
        background: #333;
        // background: darken($color-overlay, 1%);
    }
    .user {
        a {
            img {
                border: 0;
                @extend .anim;
            }
        }
    }
}