.alert {
    z-index: 103;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color-error, 0.9);
    width: 100px;
    padding: $gutter/4;
    // max-width: 510px;
    width: 100%;
    color: #fff;
    font-size: 1.2rem;
    margin: auto;
    padding-left: 180px;
    @include breakpoint_min($md) {
        padding: $gutter;
        padding-left: 200px;
    }
    i {
        display: none;
        @include breakpoint_min($md) {
            font-size: 2em;
            display: inline-block;
            vertical-align: middle;
            color: #fff;
        }
    }
    p {
        display: inline-block;
        vertical-align: middle;
        // width: calc(100% - #{$gutter*2});
        @include breakpoint_min($md) {
            padding-left: $gutter/1.5;
            width: calc(100% - #{$gutter*2});
        }
    }
}