.reactions {
    font-size: 1rem;
    min-height: 400px;
    a {
        color: $color-primary;
    }
    form, .reaction {
        @extend .clearfix;
        .user {
            @extend .pull-left;
        }
        > div {
            margin-left: $gutter*3;
            textarea {
                 height: $gutter*2;
                 min-height: $gutter*2;
                 line-height: $gutter;
                &.expanded {
                    min-height: $gutter*6;
                }
            }
        }
    }
    .reaction {
        margin: $gutter 0;
        &:hover {
            .action-trigger {
                opacity: 1;
            }
        }
        .control {
            @extend .pull-right;
        }
        .action-trigger {
            opacity: 0.1;
        }
        &:not(:last-of-type) {
            padding-bottom: $gutter/2;
            border-bottom: 1px solid $color-light;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            p {
                &:first-of-type {
                    > span {
                        @extend .text-sm;
                        @extend .text-muted;
                    }
                }
            }
        }
        .body {
            @extend .font-user-content;
        }
        .reaction {
            @include breakpoint_max($md) {
                margin-left: -$gutter*2;
            }
        }
        .reaction ~ p {
            margin-top: $gutter/2;
        }
        form {
            margin-top: $gutter;
            .btn + .btn {
                margin: 0;
            }
        }
    }
}