@keyframes rotate {
  0% { 
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-full-circle {
  0% { 
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-side {
  0% { 
    transform: rotate(0);
  }
  25% {
    transform: rotate(-35deg);
  }
  50% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes opacity {
    0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes width {
  0% { 
    width: 130px;
    padding: $gutter/2;
  }
  100% {
    width: 0;
    padding: 0;
  }
}

@keyframes pulsate {
  0% { 
    transform: scale(0.6);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
     transform: scale(0.6);
     opacity: 0.5;
  }
}

@keyframes loader-rotate {
  0% { 
    transform: rotate(0deg);
  }
  50% {
  
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
    from { 
      //font-size: 50%;
      opacity: 0; 
      transform: translateY($gutter/3);
    }
    to {
      opacity: 1; 
      //font-size: 100%;
      transform: translateY(0); // @qlitch-fix
    }
}

@keyframes fade-in-simple {
  from { 
    opacity: 0; 
  }
  to {
    opacity: 1; 
  }
}

@keyframes fade-out {
  from { 
    //font-size: 50%;
    opacity: 1; 
  }
  to {
    opacity: 0; 
  }
}

@keyframes fade-in-negative {
    from { 
      //font-size: 50%;
      opacity: 0; 
      transform: translateY(-$gutter/3);
    }
    to {
      opacity: 1; 
      //font-size: 100%;
      transform: translateY(0);
    }
}

@keyframes translate-in {
    from { 
      opacity: 1; 
      transform: translateY($gutter/3);
    }
    to {
      opacity: 1; 
      transform: translateY(0);
    }
}

@keyframes translate-left {
  from { 
    // opacity: 0; 
    transform: translateX(-25%);
  }
  to {
    opacity: 1; 
    transform: translateY(0);
  }
}

@keyframes fade-in-scale {
    from { 
      opacity: 0; 
      transform: scale(0.95) translateY($gutter/3);
    }
    to {
      opacity: 1; 
      transform: scale(1) translateY(0);
    }
}

@keyframes fade-in-x {
    from { 
      //font-size: 50%;
      opacity: 0; 
      transform: translateX(-$gutter/3);
    }
    to {
      opacity: 1; 
      //font-size: 100%;
      transform: translateX(0);
    }
}

@keyframes scaleY {
    from { 
      // opacity: 0; 
      transform: scaleY(0.95);
    }
    to {
      opacity: 1; 
      transform: scaleY(1);
    }
}

@keyframes scaleYnoglichgh {
  from { 
    // opacity: 0; 
    transform: scaleY(0.95);
  }
  to {
    opacity: 1; 
    transform: scaleY(1); // @qlitch-fix;
    // we remove  perspective(1px) because it reflow everytime we scroll. damn it. 
  }
}

@keyframes scale {
    from { 
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
}

@keyframes scale-in {
  from { 
    opacity: 1;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce {
    0% { 
      transform: translateY(-$gutter/4);
    }
    50% { 
      transform: translateY(0);
    }
    100% {
      transform:  translateY(-$gutter/4);
    }
}

@keyframes translateX {
    from { 
      opacity: 0; 
      transform:translateX(-500%);
    }
    to {
      opacity: 1; 
      transform:translateX(0)
    }
}

@keyframes neon-error {
  from { 
    @include neon($color-error, $breakpoint_min: 0);
  }
  to {
    @include neon($color-error, $intensity: 3px, $breakpoint_min: 0);
  }
}

@keyframes width-error {
  from {
    width: 40%;
    // transform: translateX(0);
    background: #fff;
  }
  to {
    width: 0%;
    //transform: translateX(-100%);
    background: $color-error;
  }
}

@keyframes glitch {
	@for $i from 0 through 9 {
		// @if ($i % 10 == 0) {
			#{$i * 1%} {
				// clip-path: polygon(0 30%, 100% 30%, 100% 35%, 0 35%);
				@if ($i % 2 == 0) {
					$start: ($i + 3) * 10%;
					clip-path: polygon(0 $start, 100% $start, 100% #{$start - 30%}, 0 #{$start - 30%});
					object-position: 10px 0;
				} 
				@else {
					$start: (10 - $i) * 10%;
					clip-path: polygon(0 $start, 100%  $start, 100% #{ $start + ($i * 0.2)}, 0 #{ $start + ($i * 0.2)});
					object-position: 0%;
				}
			// }
		}
	}
	10%, 100% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}


@keyframes background-travel {
	from {
		background-position: 0% 0%;
	}
	to {
		background-position: 0% 100%;
	}
}