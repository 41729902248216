$distance: 150%;
$dimension: $gutter/3;
$delay: 0s;

@keyframes loader-inner {
	0% { 
		transform: scale(1) translateY(0);
	}
	50% {
		transform: scale(0.9)  translateY($distance/2);
	}
	100% {
	transform: scale(1)  translateY(0);
	}
  }
  @keyframes loader {
	0% { 
	  transform: rotate(0);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
  }

.loader {
	z-index: auto;
	position: absolute;
	display: inline-flex;
	// background: $color-primary;
	// top: calc(50% - #{$dimension/2 + $border/2});
	// @todo refactor this
	// top: 3.5 + $dimension * 2.5;
	// top: calc(50% - #{$dimension/2});
	top: $gutter + $dimension/2;
	width: $dimension;
	height: $dimension;
	opacity: 0;
	background: rgba(255, 255, 255, 0.1);
	background: transparent;
	border-radius: 50%;
	// left: calc(50% - #{$dimension/2});
	transition-delay: $delay;
	@extend .anim;
	&.xl {
		$dimension: $dimension*5;
		position: relative;
		width: $dimension;
		height: $dimension;
		top: 0;
		left: calc(50% - #{$dimension/2});
	}
	&:before, &:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		// background: $color-error;
		background: $color-white; 
		border-radius: 50%;
		transform: scale(1) translateX(0);
		left: $gutter;
		transition-delay: $delay;
		@extend .anim;
	}
	&:after {
		// background: $color-succes;
		left: $gutter/2;
		background: $color-real-white; 
	}
	// @easteregg! @hover
	&.show, &:hover {
		// animation: loader 2s $transition-timing-function infinite;
		// animation-delay: 0.5s;
		opacity: 1;
		transition-delay: 0;
		transition: none;
		&::after, &::before {
			animation: loader-inner 1s $transition-timing-function infinite;
			// animation-direction: alternate;
			transition-delay: $delay;
		}	
		&::after {
			animation-delay: 0.3s;	
		}
	}
}

// header.active .loader {
// 	top: $gutter;
// }

// .menu > a:hover ~ .loader {
// 	opacity: 1;
// }