.summary {
    min-height: 50vh;
    font-size: 1rem;
    line-height: 3em;
    font-weight: 300;
    padding-top: $gutter*3;
    @extend .text-center;
    @extend .font-distinct;
    > i {
        font-size: 8em;
        padding: $gutter;
        &:not(.icon-action) {
            color: $color-section;
        }
    }
    p {
        font-size: 1.3em;
    }
    @include breakpoint_min($md) {
        min-height: 55vh;

	}
}