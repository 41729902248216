
@mixin circle($color) {
    width: 20px;
    display: inline-block;
    background: $color;
    border-radius: 50%;
    transform: scale(0.6);
}
@mixin circle_pseudo($pseudo, $color) {
    &:#{$pseudo} {
        content: "\00a0";
        @include circle($color);
        // $color: $color-succes;
        @if $pseudo == 'after' and $color == $color-succes {
            animation: pulsate 0.8s ease-out infinite;
        }

    }
}
@mixin circle_after($color) {
    @include circle_pseudo('after', $color);
}

@mixin circle_before($color) {
    @include circle_pseudo('before', $color);
    &:before {
        position: absolute;
        margin-left: -$gutter*1.2;
    }
}
