.list {
    list-style: square;
    margin: $gutter/4 $gutter;
    padding: 0;
    &.list-unstyled {
        list-style: none;
        margin: 0;
    }
    &.list-icon {
        list-style: none;
        margin: 0;
        li {
            padding-bottom: $gutter/2;
            i {
                width: $gutter*1.3;
                float: left;
                // font-size: 0.9em;
                font-size: 0.8em;
                line-height: 1.8rem;
            } 
            i + span {
                margin-left: $gutter*1.3;
                display: block;
            }
        }
    }
    li {
        margin: $gutter/4 0;
        padding: 0;
    }
}

.list-expandable-expanded {
    max-height: $gutter*18; //18
    overflow-y: auto;
    @extend .scrollbar;
    @include breakpoint_min($lg) {
        overflow-y: hidden;
    }
    li {
        opacity: 1;
        pointer-events: auto;
    }
}

.list-blend {
    // display: inline-block;
    display: block;
    margin-top: 0;
    padding-top: $gutter/2;
    padding-bottom: $gutter/2;
    $color-muted: $color-white;
    // overflow-y: hidden;
    // will-change: height;
    @include breakpoint_min($lg) {
        // for the right floating div side. (eg time explorer)
        margin-right: 250px;
    }
    li {
        opacity: 0;
        color: $color-muted;
        &.has-link {
            color: $color-secondary;
        }
        &.visible {
            opacity: 1;
        }
        a {
            color: $color-muted !important;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

list-expandable {
    // display: inline-block;
    display: block;
    min-height: $gutter*6.8;
    max-height: $gutter*6.8;
    overflow: hidden;
    @extend .anim;
    &:hover {
        @extend .list-expandable-expanded;
    }
    li {
        @extend .anim;
    }
}


.trigger-list-expandable:hover + .list-expandable {
    @extend .list-expandable-expanded;
}

.list-nav {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
}