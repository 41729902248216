@mixin neon($base-color, $steps: 5, $intensity: 10px, $breakpoint_min: $md) {
    @include breakpoint_min($breakpoint_min) {
        $value: '';
        @for $i from 0 to $steps {
            $current: 0px 0px ($i + 1)*$intensity lighten($base-color, ($i + 1)*1%);
            $value: #{$value + $current};
            @if $i < $steps - 1 {
                $value: #{$value + ','};
            }
        }
        text-shadow: $value;
    }
}