$base_padding: $gutter/3;
$border-base-color: darken($color-light, 5%); /** @inputoverlay **/
@mixin base_font() {
    font-size: 1rem;
    // font-size: 1.1rem;
    // line-height: 1.1rem;
}

.toggle {
    $gap: $gutter/8;
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    &.colorfull {
        + label {
            background: $color-error;
        }
    }
    + label {
        position: relative;
        border-radius: $border-radius;
        border-radius: $gutter;
        background: $color-muted;
        color: #fff;
        padding: $gap;
        width: $gutter*2;
        height: $gutter + $gap*2;
        // text-indent: -9999px;
        font-size: 0 !important;
        user-select: none;
        min-width: $gutter;
        i {
            font-size: 0.9rem;
            z-index: 1;
            line-height: $gutter;
            color: $color-error;
            text-align: center;
        }
        @extend .anim;
        &:after, i {
            content: '';
            position: absolute;
            width: $gutter;
            top: $gap;
            bottom: $gap;
            left: $gap;
            background: #fff;
            border-radius: 50%;
            @extend .anim;
        }
    }
    &:active + label {
        &:after, i {
            width: $gutter*1.2;
        }
    }
    &:checked + label {
        background: $color-succes;
        &:after, i {
            transform: translateX(-100%);
            left: calc(100% - #{$gap});
        }
        i {
            color: $color-succes;
            transform: translateX(-100%) rotateZ(-180deg);
        }
        
    }
}

.input-group {
    display: flex;
    align-items: center;
    label, input {
        line-height: 1.4em;
    }
    label:first-of-type {
       flex-grow: 0;
       padding: $gutter/4 0;
       padding-right: $gutter/4;
    //    min-width: 120px;
       width: 140px;
    }
    label + .input {
        flex-grow: 1;
        align-self: start;
    }
    label {
        @extend .strong;
        span {
            display: block;
            color: $color-muted;
            @extend .text-xs;
            &:after {
                content: "\00a0"; // force block even if span is empty
            }
        }
    }

    &:not(:last-of-type):not(.input-group-toggle):not(.input-group-note):not(.input-group-slim) {
        margin-bottom: $gutter;
    }
    &.input-group-slim {
        margin-bottom: $gutter/2;
    }
}
.input {
    // display: inline-block;
    border: none;
    border-bottom: 2px solid $color-light;
    padding: $gutter/4 0;
    color: $color-muted;
    background: transparent;
    // outline: none;
    @extend .anim;
    @include base_font();
    &:focus {
        border-bottom-color: darken($color-light, 5%);
    }
    &:valid {
        color: $color-succes !important;
    }
    &:invalid, &.invalid {
        color: $color-error !important;
        box-shadow: none; // @firefox
    }
    &.input-lg {
        // padding: $gutter/1.5;
        padding: $gutter/1.7;
        // font-size: 1.2rem;
        font-size: 1.1rem;
        // line-height: 1.2em;
    }
    &.input-border {
        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom-width: 2px;
        padding: $gutter/4 0;
        border-radius: 0;
        color: $color-primary !important;
        &:focus {
            border-color: darken($color-muted, 20%);
        }
    }
    &:not(.transparent).border-light {
        border-color: $border-base-color;
        &::placeholder {
            color: darken($border-base-color, 10%);
        }
    }
    &.year {
        max-width: 90px;
        min-width: 90px;
    }
    &.transparent {
        background: transparent !important;
    }
}
.input-icon {
    display: block;
    // border: 1px solid $color-muted;
    border: 1px solid $border-base-color;
    border-radius: $border-radius/2;
    margin: auto;
    margin-bottom: $gutter/2;
    background: $color-light;
    overflow: hidden; // for border-radius
    input, textarea {
        background: #fff;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    &.transparent {
        background: transparent !important;
        border-color: $color-transparent-white-3;
        .input {
            background: transparent;
            padding-left: 0;
            border: none;
            width: calc(100% - #{$gutter*2});
            &:focus {
                border-right: none;
            }
        }
        i {
            width: $gutter*2;
        }
    }
    .input {
        width: calc(100% - #{$gutter*2});
        border: 0;
        margin: 0;
        border-radius: 0;
        border-left: 1px solid $color-white;
        &:focus {
            border-right: 4px solid $color-succes;
            &:invalid, &.invalid {
                border-right-color: $color-error;
            }
        }
    }
    i {
        padding: $base_padding 0;
        // color: $color-muted;
        color: darken($border-base-color, 10%);
        width: $gutter*2;
        @extend .text-center;
        @include base_font;
    }
}

.textarea {
    font-size: 1em;
    width: 100%;
    // min-height: 45vh;
    min-height: 200px;
    border: none;
    padding: $gutter/2;
    color: $color-primary;
    // background: $color-neutral;
    // @extend .font-base;
    @extend .anim;
    @extend .border-radius;
}

form > .textarea {
    // border: 1px solid $color-muted;
    border: 1px solid $border-base-color; 
}