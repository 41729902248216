.slides {
    position: relative;
    font-size: 1rem;
    overflow: hidden;
    @extend .border-radius;
    .slide-arrow {
        $base-metric: 4rem;
        position: absolute;
        z-index: 1;
        font-size: $base-metric;
        top: calc(50% - #{$base-metric / 2} ); // because of slides with showcases.
        opacity: 0.3;
        @extend .cursor-pointer, .anim;
        @include breakpoint_max($md) {
            display: none;
        }
        &:hover {
            opacity: 1;
        }
        &.slide-next {
            right: $gutter;
        }
        &.slide-prev {
            left: $gutter;
        }
    }
    .slides-overflow {
        overflow: hidden;
        // @extend .border-radius;
        $height-md: $gutter-big*12;
        $height-md: $gutter-big*13;
        // min-height: $height-md;
        // no min height anymore. we removed main slide.
        // @include breakpoint_max($lg) {
        //     min-height: calc(60vh);
        // }
        // @include breakpoint_max($md) {
        //     min-height: calc(100vh - #{$gutter*3 + $browser-header});
        // }
        .slides-wrapper {
            position: relative;
            @extend .clearfix;
            //@include breakpoint_min($lg) {
                &:not(.paning) {
                    transition: transform $transition-timing*1.5 $transition-timing-function;
                }
            //}
            .has-cover {
                .title {
                    visibility: hidden;
                }
            }
            .slide {
                transform: scale(1);
                opacity: 1;
                transition: transform $transition-timing*1.5 $transition-timing-function,
                            opacity $transition-timing*1.5 $transition-timing-function;
                @extend .pull-left;
                @include breakpoint_max($md) {
                    .icon {
                        font-size: 4rem;
                        padding: $gutter/4;
                    }
                    ul {
                        font-size: 1.1rem;
                    }
                }
                &:not(.has-cover) {
                    // padding-bottom: $gutter*2;
                    padding-bottom: $gutter;
                }
                // if no class defined inside the slide div, then we go with default
                > div:not([class]) {
                    // position: relative;
                    padding: $gutter;
                    padding-bottom: 0;
                    font-size: 1.2em;
                    line-height: 1.4em;
                    @extend .max-width;
                    @extend .text-center;
                }
                &:not(.active) {
                    opacity: 0.05;
                    opacity: 1;
                    pointer-events: none;
                    // nice effect but has problem with opacity.
                    // @include breakpoint_min($lg) {
                    //     transform: scale(0.8); // was 0.5
                    // }
                }
                /*
                was for:
                <div className="image">
                                <Link className="link" to="/seibz">
                                    {ShowcaseImage}
                                </Link>
                            </div>
                .image {
                    position: relative;
                    img {
                        // z-index:1;
                        position: absolute;
                        left: 0;
                        bottom: -$gutter;
                        width: 100%;
                        left: 0;
                        border: 2px solid #fff;
                        border-radius: $border-radius;
                        @extend .opacity-05;
                        @extend .anim;
                        &:hover {
                            transform: translateY($gutter*14);
                            @extend .opacity-1;
                        }
                    }
                }
                */
            }
        }
    }
    .slides-navigation {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        // background: $color-transparent-black-1;
        padding: $gutter/2;
        // padding-top: 0;
        // margin: -$gutter;
        @extend .text-center;
        // @extend .anim;
        // &.slides-navigation-alternate {
        //     background: #fff;
        //     @extend .bg-light;
        //     div {
        //         border: 1px solid $color-muted;
        //     }
        // }
        div {
            $base_dimension: $gutter*0.9;
            $base_dimension: $gutter*0.7;
            display: inline-block;
            vertical-align: middle;
            line-height: $base_dimension;
            width: $base_dimension;
            height: $base_dimension;
            border-radius: 50%;
            margin: 0 $gutter/4;
            border: 1px solid $color-real-white;
            // @extend .border-transparent-white;
            @extend .text-center;
            @extend .cursor-pointer;
            &.active, &:hover {
                background: $color-real-white;
                border-color: transparent;
                @extend .border;
            }
        }
    }
    .title:not(:empty) {
        // z-index: 1;
        position: relative;
        // padding: $gutter*1.5 $gutter/2;
        padding: $gutter $gutter/2;
        background: $color-transparent-black-5;
        color: $color-primary;
        // background: linear-gradient(to bottom, $color-primary, $color-white);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // border-radius: $border-radius $border-radius 0 0;
        // margin: 0 -#{$gutter};
        @extend .text-center;
        @include breakpoint_max($md) {
            font-size: 2rem;
            padding: $gutter $gutter/4;
        }
        p {
            font-size: 1rem;
            color: $color-white;
            @extend .font-weight-300;
        }
    }
}