.timeline {
    display: flex;
    flex-direction: column;
    $spacing: $gutter;
    $color: $color-primary;

    div.text-uppercase {
        margin-bottom: $gutter/2;
        margin-top: $gutter;
        @extend .text-sm;
        @extend .strong;
    }
    .timeline-year {
        color: $color;
        @extend .display-1;
    }
    .timeline-phones {
        .timeline-phones-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -$gutter/4;
            span {
                display: block;
                background: #fff;
                margin: $gutter/4 $gutter/4;
                padding: $gutter/4 0;
                // flex-basis: 40px;
                width: 100px;
                border: 1px solid transparent;
                @extend .box-shadow;
                @extend .border-radius;
                @extend .text-center;
                @extend .anim;
                &:hover, &.current {
                    border-color: $color-accent;
                }
                img {
                    width: 80px;
                    height: 105px;
                }
            }
            a.has-current {
                span:not(.current) {
                    filter: blur(2px);
                }
            }
        }
    }
    .timeline-facts {
        text-align: left;
        max-width: 100 * 5 + ($gutter * 2);
        padding: $gutter/2;
        padding-left: $gutter*1.5;
        margin: 0;
        background: #fff;
        @extend .box-shadow;
        @extend .border-radius;
        li {
            margin-right: $gutter;
            position: relative;
            @include breakpoint_max($md) {
                margin-right: 0;
                span {
                    display: none !important;
                }
            }
        }
    }
    .timeline-section {
        padding: $gutter 0;
        @include breakpoint_min($lg) {
            padding: $gutter;
            width: 50%;
            flex-basis: 100%;
            $border-color: darken($color-light, 10%);
            > p {
                position: relative;
                &:after, &:before {
                    content: '';
                    position: absolute;
                    bottom: 0.6em;
                    height: 1px;
                    width: $spacing/1.2;
                    background: $border-color;
                }
                &:before {
                    height: $gutter/2;
                    width: $gutter/2;
                    bottom: 0.48em;
                    background: $color-primary;
                    border-radius: 3px;
                    z-index: 1;
                }
            }
            &:nth-child(even) {
                text-align: right;
                border-right: 2px solid $border-color;
                > p {
                    &:after {
                        right: -$gutter;
                    }
                    &:before {
                        right: -$gutter*1 - $gutter/4;
                    }
                }
                .timeline-facts {
                    margin-left: auto;
                }
                .timeline-phones-list {
                    justify-content: flex-end;
                    margin-right: -$gutter/4;
                }
            }
            &:nth-child(odd) {
                align-self: flex-end;
                border-left: 2px solid $border-color;
                left: -2px;
                position: relative;
                > p {
                    &:after {
                        left: -$spacing;
                    } 
                    &:before {
                        left: -$gutter*1 - $gutter/4;
                    }
                }

                .timeline-phones-list {
                    margin-left: -$gutter/4;
                }
            }
        }
    }
}