.reviews {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    @include breakpoint_min($lg) {
        flex-direction: row;
    }
    a {
        color: $color-primary;
        @extend .strong;
    }
    > div {
        flex-shrink: 1;
        @include breakpoint_min($lg) {
            width: 48%;
            width: calc(50% - #{$gutter/2});
            + div {
                margin-left: $gutter/2;
            }
        }
    }
    .review {
        position: relative;
        padding: $gutter/2;
        margin: $gutter/4 0;
        // font-size: 0.9rem;
        font-size: 1rem;
        // line-height: 1.4em;
        // border: 1px solid transparent;
        // border-left: 3px solid transparent;
        &:hover {
            > a {
                transform: translateY(-$gutter/2);
            }
            .area-fifty1 {
                @extend .box-shadow;
            }
        }
        @include breakpoint_max($sm) {
            padding-left: $gutter/4;
            padding-right: $gutter/4;
        }
        &.history-has-not-been-written-yet {
            .area-fifty1 {
                border: 1px solid $color-succes;
                &:after {
                    display: none;
                }
            }
            .control .btn.action-trigger {
                display: none;
            }
        }
        &:hover, &.highlight {
            // @extend .box-shadow;
            &.y {
                border-color: $color-succes;
            }
            &.n {
                border-color: $color-error;
            }
            &.na {
                border-color: $color-muted;
            }
            .action-trigger {
                opacity: 1;
            }
        }
        &.shadow {
            @extend .box-shadow;
            $_gap_dimension: 7px;
            $_gap_color: #fff;
            &::after, &::before {
                position: absolute;
                content: '';
                height: $_gap_dimension;
                width: 100%;
                bottom: -$_gap_dimension;
                right: 0;
                background: $_gap_color;
            }
            &::after {
                height: 110%;
                width: $_gap_dimension;
                bottom: -$_gap_dimension;
                right: -$_gap_dimension;
                background: $_gap_color;
            }
        }
        &.editable {
            .control {
                right: $gutter/2;
                top: $gutter/1.2;
                .btn {
                    padding: $gutter/4;
                }
            }
        }
        .control {
            // padding-bottom: $gutter/4;
            // @extend .pull-right;
            display: inline-block;
            position: absolute;
            //margin-top: $gutter/3;
            top: $gutter/1.3; // sori
            // margin-left: $gutter/2;
            .btn {
                // @extend .text-uppercase;
                padding: $gutter/6;
                padding-bottom: $gutter/6;
            }
            @include breakpoint_max($sm) {
                &.wide {
                    width: 100%;
                }
                padding-bottom: $gutter/2;
                text-align: right;
            }
        }
        .action-trigger {
            opacity: 0.1;
        }
        textarea {
            // min-height: 35vh;
            min-height: 200px;
            border-radius: $border-radius;
        }
        .body {
            // border-left: 1px solid $color-neutral;
            // @initially: this changed based on medium (phone / user)
            @include breakpoint_min(10000px) {
                margin-left: $gutter*2 + $gutter/2;
                padding-left: $gutter/2;
                min-height: 80px;
            }
            .area-fifty1 {
                padding: $gutter/2;
                border-radius: $border-radius;
                border: 1px solid darken($color-light, 5%);
                background: $color-light;
                position: relative;
                border-bottom-width: 2px;
                @extend .anim;
                // &:after {
                //     content: '';
                //     position: absolute;
                //     border-radius: $border-radius;
                //     z-index: -1;
                //     width: $gutter*4;
                //     height: $gutter*1.5;
                //     top: -2px;
                //     right: -2px;
                //     // background: $color-muted;
                // }
                .user {
                    // padding-left: $gutter/2;
                    padding-right: $gutter/2;
                    img {
                        @each $dimension in width height min-width min-height {
                            #{$dimension}: 20px;
                          }
                    }
                }
            }
            > span {
                // padding-bottom: $gutter/3;
                // padding-bottom: $gutter/2;
                // padding-top: $gutter/2;
                // padding-bottom: $gutter/2;
                padding: $gutter/3 $gutter/2;
                // width: calc(100% - 80px); // size of device image
                display: inline-block;
                // @extend .text-md;
                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    white-space: nowrap;
                    vertical-align: middle;
                    @extend .strong;
                    @include breakpoint_max($md) {
                        max-width: 200px;
                    }
                }
            }
            .color {
                display: inline-block;
                vertical-align: sup;
            }
            .text {
                // margin-bottom: $gutter/3;
                margin-bottom: $gutter/8;
                margin-bottom: $gutter/4;
                margin-top: 0;
                @extend .font-user-content;
            }
            p:last-of-type {
                margin: 0;   
            }
            // i am hiding this way cos to lazy lol. (eg: when we display + we hide edit button)
            // doesn't work anymore, you lazy ass.
            .icon + span + .control {
                display: none;
            }
        }
        &.y {
            > divX {
                border-color: $color-succes;
            }
            .color, .color a {
                color: $color-succes;
            }
            .area-fifty1 {
                border-bottom-color: lighten($color-succes, 20%);
            }
        }
        &.n {
            > divX {
                border-color: $color-error;
            }
            .color, .color a {
                color: $color-error;
            }
            .area-fifty1 {
                border-bottom-color: lighten($color-error, 20%);
            }
        }
        > a {
            // @extend .pull-left;
            // @extend .pull-right;
            position: absolute;
            right: $gutter/2;
            @extend .anim;
        }
        .user {
            top: 0;
            left: $gutter/4;
            padding-right: $gutter/4;
            @extend .pull-left;
        }
        > a {
            @extend .overlay-bg-white-fix;
            img {
                display: block;
                $_width: 60px;
                width: $_width;
                + .body {
                    margin-left: $_width + $gutter/2;
            }
        }
        }
    }
}

// swap da color
.bg-light {
    .area-fifty1 {
        background: #fff !important;
    }
}