// create delayed transation, eg cascadized items. (@example user)
@mixin transition-delay($from: 1, $to: 5, $durationFactor: 0, $initialDelay: 0s) {
    @for $i from $from to $to {
        &:nth-child(#{$i}) {
            transition-delay: $initialDelay+$i/30;
            @if $durationFactor {
                transition-duration: 0.1s+($to - $i)/10+$durationFactor;
            }
        }
    }
}

@mixin transition-hover-scale($scale: 1.2) {
    @extend .anim;
    &:hover {
		transform: scale($scale);
	}	
}

@mixin transition-translate($axis, $reset: false, $distance: $gutter) {
    $distance: -$distance;
    @if $reset {
        opacity: 1;
        transform: translate#{$axis+'(0)'};
    }
    @else {
        opacity: 0;
        transform: translate#{$axis+'('+$distance+')'};
        @extend .anim;
    }
}