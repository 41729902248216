.block-separator {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	@include breakpoint_max($md) {
		flex-flow: row wrap;
		blockquote {
			display: none;
		}
	}
	> * {
		display: block;
		@include breakpoint_max($md) {
			width: 100%;
			text-align: center;
			margin: auto;
		}
	}
	> *:first-child {
		flex: 0 0 $gutter*6;
		@include breakpoint_min($md) {
			margin: 0 $gutter;
		}
	}
	> *:last-child {
		flex: auto;
		@include breakpoint_min($md) {
			// padding-left: $gutter*1;
			// border-left: 1px solid $color-transparent-white-1;
			// margin-left: $gutter;
			// padding-left: $gutter*2;
			margin-left: $gutter*2;
			// padding: $gutter;
		}
	}
	blockquote {
		padding: $gutter/4 0;
	}
}
.text-separator {
	font-size: 1rem;
	&.text-separator-dark {
		span:last-of-type {
			color: $color-primary;
		}
	}
	&.text-lg {
		@extend .text-lg;
	}
	&.text-sm {
		@extend .text-sm;
	}
	span:first-of-type {
		margin-right: $gutter/4;
		@extend .text-uppercase;
	}
	span:last-of-type {
		font-style: normal;
		@extend .text-lg;
		// @include breakpoint_min($lg) {
		// 	color: $color-white;
		// }
	}
}