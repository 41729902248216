.length-counter {
    color: $color-muted;
    margin: 0 $gutter/4;
    vertical-align: middle;
    &.valid {
        color: $color-succes;
    }
    &.invalid {
        color: $color-error;
    }
}