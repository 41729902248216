.tooltip {
    position: relative;
    user-select: none;
    &:hover {
        > .tip {
            pointer-events: all;
            opacity: 1;
            &.bottom {
                transform: translateY($gutter/2);
            }
            &.top {
                transform: translateY(-$gutter/2);
            }
            z-index: 99;
        }
        > :nth-child(2) {
            transform: scale(1.2);
            @extend .cursor-pointer;
            @extend .anim;
        }
    }
    > .tip {
        position: absolute;
        pointer-events: none;
        min-width: 100%;
        font-size: 0.9rem;
        // margin-top: $gutter*1.6;
        color: $color-muted;
        // top: 0;
        left: 0;
        opacity: 0;
        user-select: none;
        @extend .text-nowrap;
        @extend .text-center;
        @extend .anim;
        // @include transition-translate(Y, $distance: $gutter);
    }
    > :nth-child(2) {
        display: inline-block; // for transform
        @extend .anim;
    }
}