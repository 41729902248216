.featured {
    $backgrond-color: rgb(249, 253, 255);
    $backgrond-color: $color-transparent-white-7;
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    @include breakpoint_min($md) {
        padding: 0 $gutter*2;
    }
    .user {
        margin: auto;
        // width: 100%;
        text-align: center;
        // @include breakpoint_min($md) {
        //     padding-left: $gutter;
        // }
        img {
            background-color: $color-primary;
            border-color: #fff;
            @include breakpoint_max($md) {
                width: $gutter*3;
                height: $gutter*3;
            }
        }
    }
    p {
        @extend .text-center;
    }
    blockquote {
        // text-align: left;
        background: $backgrond-color;
        margin: $gutter 0;
        margin-top: -$gutter*2;
        border: 2px solid #fff;
        // padding-top: $gutter*2;
        padding-top: $gutter*1.6;
        font-size: 1rem;
        @extend .padding;
        @extend .border-radius;
        @include breakpoint_max($md) {
            display: none;
        }
        @include breakpoint_min($md) {
            min-height: 260px;
        }
        // color: $color-white;
        &:after, &:before {
            display: none;
        }
    }
    > div {
        /* don't grow, don't shrink, fixed 50% */
        // flex: 0 0 50%;
        width: 33.33%;
        padding: $gutter/2 $gutter/2;
        // @extend .text-shadow;
        &:hover {
            // transform: scale(1.05);
            blockquote {
                @include box-shadow($color: rgba(0, 0, 0, 0.1), $length: 15px);
            }
        }
        @include breakpoint_max($md) {
            width: 33.33% !important;
            text-align: center;
            font-size: 0.8rem;
        }
        @include breakpoint_max($xl) {
            width: 50%;
        }
        @include breakpoint_min($xl) {
            padding: $gutter;
        }
        // mobile.
        // @include breakpoint_max($md) {
        //     flex-flow: column wrap;
        //     width: 100%;
        //     border-bottom: 1px solid $color-transparent-white-3;
        // }
        // @include breakpoint_min($md) {
        //     &:nth-last-child(-n+2) {
        //         border-color: transparent;
        //     }
        // }
        &:last-of-type {
            border-bottom: none;
        }
        // p {
        //     @extend .text-secondary;
        //     @extend .text-lg;
        // }
        // a {
        //     @extend .text-secondary;
        // }
    }
}