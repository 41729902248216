header {
	z-index: 99;
	position: -webkit-sticky;
	position: sticky;
	position: fixed; // @sticky-exp
	top: 0;
	left: 0;
	right: 0;
	padding: $gutter/2.5 $gutter/2;

	line-height: $gutter*2.1; // @header-mobile-fix // @hader-same-height-with-avatar
	min-height: $gutter*2;
	transition-delay: $transition-timing*0.1;
	background: $header-background;
	@extend .anim;
	// &.active {
	// 	background: $header-background;
	// }
	&.sticky {
		background: rgba($header-background, 0.9);
	}
	@include breakpoint_max($lg) {
		// this makes the text look fuzzy.. at least on Android chrome
		-webkit-backdrop-filter: blur($gutter/2);
		backdrop-filter: blur($gutter/2);
		padding: $gutter/3;
		padding-left: 0;
		padding-right: 0;
		background: transparent;
		&.sticky, &.active {
			background: rgba($main-bg, .9);
		}
	}
	&.alternate {
		&.sticky, &.active, &:not(.fafa) {
			background: rgba(255, 255, 255, 0.1);
			// @extend .box-shadow-light;
			.logo {
				mix-blend-mode: difference;
				opacity: 0.8;
			}
			.phones, .bg-light {
				background: transparent !important;
			}
			a {
				color: $color-secondary !important;
				font-weight: 300;;
			}
		}
	}

	.logo {
		$width: 220px;
		$width: 200px;
		opacity: 0.85;
		width: $width; // alwasys be @ 220
		// max-height: 37px;
		display: inline-block;
		vertical-align: middle;
		margin-left: $gutter/4;
		max-width: none;
		@extend .anim;
		&:hover {
			opacity: 1;
		}
	}

	@include breakpoint_max($sm) {
		.logo-link {
			// background: red;
			width: 47px;
			height: 30px;
			overflow: hidden;
			position: absolute;
			left: 0;
			right: 0;
			top: $gutter/1.3;
			margin: auto;
		}
		.logo {
			width: 170px;
			position: absolute;
			left: -7px;
			top: 0;
		}
		&.search-active {
			.logo {
				display: none;
			}
		}
	}

	// .logo-link {
	// 	$width: 210px;
	// 	width: $width;
	// 	overflow: hidden;
	// 	display: inline-flex;
	// 	vertical-align: middle;
	// 	@extend .anim;
	// }
	.logo-text {
		display: none;
	}
	h1 {
		color: $color-white;
		text-align: center;
		@extend .text-shadow;
	}
	> div {
		padding-left: $gutter/2;
		padding-right: $gutter/2;
		@extend .anim;
	}
}

// .busy {
// 	header {
// 		.logo-link {
// 			width: 65px;
// 		}
// 	}
// }

.page-about {
	header {
		.logo-text {
			display: block;
			position: absolute;
			opacity: 0;
			pointer-events: none;
			top: $gutter/4;
			left: 50%;
			transform: translateX(-70%) scale(1.1);
			transition-duration: $transition-timing*2;
			transition-delay: $transition-timing*4;
			// font-weight: bold;
			@extend .text-white;
			@extend .title;
			@extend .anim;
			&:before {
				content: 'what is';
				position: absolute;
				top: -$gutter/3;
				font-size: 0.4em;
				font-weight: 300;
				transition-delay: $animation-timing*4;
				@extend .text-neutral;
				@extend .text-uppercase;
				@extend .anim;
			}
			i {
				display: inline-block;
				font-style: normal;
				transition: transform $transition-timing*2;
				transition-delay: 0s;
				&:first-of-type:after {
					content: "obiles ";
					font-size: 0;
					opacity: 0;
					max-width: 0;
					overflow: hidden;
					// @extend .text-white;
					transition-duration: $transition-timing*2;
					transition-delay: $transition-timing*2;
					@extend .anim;
				}
			}
			
		}
		.logo-link:hover ~ .logo-text {
			@include breakpoint_min($lg) {
				opacity: 1;
				transform: translateX(-70%)  scale(1);
				// transition-duration: $transition-timing*2;
				transition-delay: 0s;
				// transform: translateX($gutter);
				// @extend .animate-glitch;
				// animation-delay: $transition-timing*2;
				// @include animation(bounce, $time: infinite, $timing-function: linear);
				&:before { 
					opacity: 0;
					transform: translateY($gutter/4);
					transition-delay: $animation-timing*1.5;
				}
				i {
					//	@include animation(glitch, $time: infinite, $timing-function: linear, $duration: 3s);
					// text-transform: capitalize;
					// transition-delay: $animation-timing;
					transition-delay: $transition-delay*4.5;
					&:first-of-type:after {
						font-size: 1em;
						opacity: 1;
						padding-right: $gutter/2;
						max-width: $gutter*4;
						transition-delay: $animation-timing*2;
					}
					&:first-of-type {
						transform: translateX(105%);
					}
					&:last-of-type {
						transform: translateX(-105%);
					}
				}
			}
		}
	}
}


body {
	&.header-not-sticky {
		header {
			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	top: 0;
			// 	bottom: -$gutter*10;
			// 	left: 0;
			// 	right: 0;
			// 	background: linear-gradient($color-background-base, transparent);
			// }
			&:not(.sticky) {
				transition: none;
				.navbar a:not(:hover) {
					@extend .text-shadow-subtle;
				}
			}
			&:not(.search-active) { 
				&.menu-active:before {
					background: rgba($header-background, 0.9);
					transition-delay: 0s;
					@extend .blur-backdrop;
				}
				@include breakpoint_min($md) {
					backdrop-filter: none;
					&:not(.active) {
						background: transparent;
					}
					// &.sticky {
					// 	padding-top: $gutter;
					// 	> div {
					// 		max-width: $max-width + $gutter * 4;
					// 		@include breakpoint_min($xxl) {
					// 			max-width: $max-width-xxl + $gutter * 4;;
					// 			// max-width: $max-width;
					// 		}
					// 	}
					// }
					&.sticky {
						&:not(.active) {
							.logo, .search-bar, .btn, .user, .navbar {
								opacity: 0;
							}
						}
						.menu {
							.initiator {
								&::before {
									transform: scaleY(1);
									left: -$gutter/4;
									right: -$gutter/4;
								}
								span {
									background: $color-background-base;
								}
							}
						}
					}
				}
				// @extend .anim; 
				
			}
			.logo, .search-bar, .btn, .user {
				@extend .anim;
			}
			.menu {
				&:not(.active) {
					.initiator {
						span {
							background: #fff;
							// height: 6px;
							// margin: 0;
							// background: rgba(255, 255, 255, 0.9);
							//   mix-blend-mode: multiply; // doesnt work with fixed
							// box-shadow: 0 0 3px rgba($color-background-base, 0.3);
							// border: 1px solid rgba($color-background-base, 0.9);
							// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
						}
					}
				}
				.initiator {
					&::before {
						content: '';
						position: absolute;
						background: #fff;
						transform: scaleY(0);
						transform-origin: center;
						z-index: -1;
						top: -$gutter/4;
						left: 0;
						right: 0;
						bottom: -$gutter/4;
						@extend .anim;
						@extend .border-radius;
					}
				}
			}
		}
		header, .menu, .menu .data {
			transition-delay: 0s;
		}
		.link-home {
			display: none;
		}
	}
}

// header:hover .logo {
// 	@extend .animate-glitch;
// }