// templating file
@import "bootstrap.scss";
// import "../fa/font-awesome.scss"; // used external

.svg-loader {
	width: 60%;
	max-width: 500px;
	path {
		// fill: #fff;
	// width: 100%;
	stroke: #fff;
	fill: none;
	fill:none;
	stroke-width:10;
	// stroke-width:7;
	stroke-linecap:round;
	stroke-linejoin:round;
	stroke-miterlimit:10;
	}
}
$header-base-metric: $gutter*2.6;
$header-base-metric: $gutter*2.85; // @appgap
$header-base-metric: $gutter*2.93; // @appgap

:root {
	--color-success: #{rgba($color-succes, 1)};
	--color-error: #{rgba($color-error, 1)};
}

// @todo add style section (for margin, padding, etc)
html {
    // overflow: scroll;
    // overflow-x: hidden;
	line-height: 100px;
	font-size: $font-size;
	line-height: 1.5em;
	scroll-padding-top: $gutter*2;
	
	@include breakpoint_min($xxl) {
		// zoom: 1.2;
		font-size: $font-size-xxl;
	}

	// @quikfix skrew this
	@include breakpoint_min($xxxl) {
		font-size: $font-size-xxxl;
		zoom: 150%;
		// transform: scale(2.2);
		// transform-origin: top center;
		.full-section,  .cover {
			min-height: 67vh !important;
		}
		.cover-container, .cover {
			zoom: 100%;
			height: 67vh;
		}
	}
	@include breakpoint_min($v4k) {
		font-size: $font-size-xxxl;
		zoom: 220%;
		// transform: scale(2.2);
		// transform-origin: top center;
		.full-section,  .cover {
			min-height: 46vh !important;
		}
		.cover-container, .cover {
			zoom: 100%;
			height: 46vh;
		}
	}
	@include breakpoint_min($v4k) {
		zoom: 300%;
		.full-section, .cover {
			min-height: 33.333vh !important;
		}
		.cover-container, .cover {
			zoom: 100%;
			height: 33.333vh;
		}
	}
}

.scrollbar {
	@include breakpoint_min($xl) {
		&::-webkit-scrollbar {
			width: $gutter/4;
			height: $gutter/3;
		}
		&::-webkit-scrollbar-track {
			// box-shadow: inset 0 0 $gutter/3 $color-transparent-black-1; 
			// background: $color-transparent-black-1;
			background: none;
		}
		&::-webkit-scrollbar-thumb {
			// background: $color-primary;
			// background: $color-secondary;
			background: darken($color-primary, 10%);
			&:hover {
				background: darken($color-primary, 5%);
			}
			// @extend .border-radius;
		}
	}
}

body {
	width: 100%; 
	height: 100%;
	padding: 0;
	margin: 0;
	color: $color-text;
	background: $main-bg; // @bgphotocolor
	// border-top: 4px solid $color-error;
	@extend .font-base;
	// zoom: 1.25; zoom: 175%;
	&.scroll-freeze {
		// @include breakpoint_max($md) {
			overflow: hidden;
		// }
	}
}
a {
	text-decoration: none;
	// color: $color-section;
	// color: $color-secondary;
	color: $color-primary;
	@extend .tap-highlight-transparent;
	&.next {
		span {
			position: absolute;
			// top: 0;
			// left: 0;   
			display: inline-block;
			opacity: 0;
			padding-left: $gutter/2;
			transform: translateX(-$gutter/2);
			@extend .text-uppercase;
			@extend .text-sm;
			@extend .anim;
			@extend .strong;
		}
		i {
			display: inline-block;
			@extend .anim;
		}
		&:hover {
			i {
				transform: translateX(2px);
			}
			span {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
.has-next:hover {
	a.next span {
		opacity: 1;
		transform: translateX(0);
	}
}
ul, li {
	margin: 0;
	padding: $gutter/4 $gutter/2;
}
h1, h2, h4, .title {
	margin: 0;
	padding: $gutter/2 0;
	line-height: 1.1em;
	@extend .font-distinct;
}
h1 {
	span {
		// we use inline-block so the animations will work properly.
		display: inline-block;
	}
}
h1, .title {
	// font-size: 2.8rem;
	font-size: 2.6rem; // 2.2
	@include breakpoint_min($md) {
		// font-size: 2.6rem;
		// font-size: 2.1rem;
		font-size: 2.4rem;
	}
}
h2, .h2 {
	font-size: 1.6rem;
}
h2, .h2 {
	padding: $gutter/6 0;
	@extend .font-weight-300;
}
h3, h4, h5, h6 {
	@extend .font-weight-300;
}
b {
	// font-weight: 400;
	@extend .strong;
}
p, blockquote {
	margin: $gutter/4 0;
	padding: 0;
}
blockquote {
	text-align: justify;
	font-size: 1.2rem;
	// background: linear-gradient(to bottom, #7f5a83, $color-white);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	@extend .font-user-content;
	// quotes:'\201C' '\201D' '\2018' '\2019';
	// &::before {
	// 	// margin-left: -$gutter/2;
	// 	margin-left: -$gutter/1.2;
	// 	content: open-quote;
	// }
	// &::after {
	// 	content: close-quote;
	// 	// margin-left: $gutter/3;
	// 	margin-left: $gutter/6;
	// 	// content: '';
	// 	// margin-left: 0;
	// }
	// &::after, &::before {
	// 	position: absolute;
	// 	color: $color-muted;
	// 	// color: $color-primary;	
	// 	font-size: 3rem;
	// 	line-height: 2.5rem;
	// 	// @extend .text-xlg;
	// }
	i.fa {
		position: absolute;
		// padding-left: $gutter*1.2;
		padding-left: $gutter/2;

		color: rgba($color-neutral, 0.5);
		&:hover {
			color: $color-white;
		}
	}
}
img {
	max-width: 100%;
}
.busy * {
	cursor: wait !important;

	// .app {
	// 	border-color: $color-accent;
	// }
}

* {
	box-sizing: border-box;
}
.app, .welcome-home, header, footer {
	// @extend .font-weight-300;
	@extend .font-base;
}
.app {
	position: relative;
	// overflow: hidden; // @performacne problems on fast scrolls.
	// overflow: hidden; // @recheck later if this creates more problems!! @edit: it creates performance problems with sticky elemetns inside.
	z-index: 2;
	margin-top: $gutter*2.8; 
	background: $color-overlay;
	// background: linear-gradient(to bottom, $main-bg 200px, $color-overlay 20px);
	
	// &:before {
	// 	// background-image: url($cover-back-path);
	// 	// background-color: rgba($color-background-base, 0.9);
	// 	// background-blend-mode: luminosity;
	// 	background: linear-gradient(to bottom, $color-background-base, $color-light);
	// 	content: "";
	// 	position: absolute;
	// 	background-position: bottom;
	// 	background-size: 450%;
	// 	top: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	right: 0;
	// 	// filter: blur(40px);
	// }
	@extend .anim;
	@include breakpoint_min($md) {
		margin-top: $header-base-metric; // @sticky-exp
	}

	.header-xl {
		> div:first-of-type {
			min-height: 50vh;
		}
		.header-xl-up {
			margin-top: calc(-50vh + #{$gutter*5});
		}
		@include breakpoint_max($sm) {
			.headline-xl {
				display: flex;
			}
			.header-xl-up {
				margin-top: -$gutter*10;
			}
		}
	}
	&.overlay-screen-active {
		transition: none;
		// z-index: 100; // safari doesn't play good with this one, it shows bottom part of phones over overlay.
	}
	.content {
		position: relative;

		.attention-overlay {
			height: calc(100vh - #{$gutter*3}); // we better have it fullscreen
			height: calc(100vh - #{$gutter*2.85}); // we better have it fullscreen
		}
		// @yea, backward compatible.
		> div > div {
			&:first-of-type {
				padding: $gutter*2 0;
			}
			&:not(.transparent):not(.overlay):not(.bg-primary) {
				color: $color-text;
				background: #fff;	
			}
			&:not(.no-padding) {
				padding: $gutter;
				@include breakpoint_max($sm) {
					padding: $gutter $gutter/2;
				}
			}
			
		}
		> .transparent-zero {
			background: transparent;
		}
		// <-- main content end
		
			
  
		.transparent {
			// color: #8A2BE2;
			color: $color-white;
			h1, > h2, > h3, > .title {
				// color: $color-accent;
				// color: $color-white;
				color: #fff;
				// text-shadow: 1px 1px 3px darken($color-overlay, 10%);
			}
			&.headline {
				// background: radial-gradient(circle at top left, $color-background-base, $color-overlay) !important;
				// background-size: 400% 300%;
				// background: radial-gradient(circle at top right, rgba(0, 0, 0, 0.2) 30%, transparent 30%), 
				// radial-gradient(circle at bottom right, rgba(0, 0, 0, 0.2) 50%, transparent 50%),
				// radial-gradient(circle at top left, rgba(0, 0, 0, 0.2) 15%, transparent 15%) !important;
				// background: linear-gradient(to bottom, $color-background-base 10%, $color-overlay 50%) !important;
				&:not(.headline-cover):not(.headline-simple) {
					background: linear-gradient(to top, $color-overlay 0% 50%, $color-background-base 50% 100%);
					background-size: 200% 200% ;
					// animation: background-travel 0.9s $transition-timing-function 1 forwards;
					animation: background-travel 0.9s 1 forwards;
				}
				a:not(.btn) {
					color: $color-secondary;
				}
				p:not([class]) {
					font-size: 1.1rem;
				}
				h1, .title {
					// color: $color-secondary-transparent;
					// @extend .text-shadow;
					// @include neon(rgba($color-secondary-transparent, 0.5), $steps: 2, $intensity: 16px);

					// background: linear-gradient(to bottom, $color-primary, $color-accent);
					// -webkit-background-clip: text;
					// -webkit-text-fill-color: transparent;

					// color: rgba($color-primary, 1);
					// @include neon(rgba($color-primary, 1), $steps: 2, $intensity: 16px);
					// color: rgba(255, 255, 255, 0.7);
					// color: lighten($color-background-base, 30%);
					// font-weight: 300;
					font-weight: 200;
					@include breakpoint_min($lg) {
						padding-bottom: $gutter/8;
					}
				}
			}
		}
		.headline {
			padding: $gutter 0;
			&.headline-xl {
				@include breakpoint_min($md) {
					padding-top: $gutter*3;
					padding-bottom: $gutter*2;
				}
			}
			&.headline-xxl {
				@include breakpoint_min($md) {
					padding-top: $gutter*3;
					padding-bottom: $gutter*3;
				}
			}
			&.headline-lg {
				@include breakpoint_min($md) {
					padding-top: $gutter*2;
					padding-bottom: $gutter*2;
				}
			}
		}
	}
}

footer {
	// z-index: 2;
	// position: relative;
	padding-top: $gutter;
	background: $color-background-base;
	// padding-bottom: $gutter;
	// background: $color-transparent-black-7;
	@extend .text-neutral;
	// @extend .border-top-transparent-white;
	@include breakpoint_min($md) {
		// border-radius: 0 0 $border-radius*1 $border-radius*1;
		// margin-top: $gutter*4;
		padding-top: $gutter*2;
		// margin-top: -24px;
		// z-index: 1;
		padding: $gutter;
		padding-top: $gutter*2;
		padding-left: 0;
		padding-right: 0;
		// margin-bottom: -$gutter;
	} 

	@include breakpoint_min($xxl) {
		padding: $gutter*2;
		padding-bottom: $gutter;
		padding-left: 0;
		padding-right: 0;
		// margin-bottom: -$gutter*2;
	} 

	img {
		max-width: 240px;
	}
	ul {
		margin: 0 !important;	
		a {
			font-size: 1.2rem;
		}
	}
	a {
		color: rgba($color-white, 0.65);
	}
	.section {
		font-size: 1.2rem;
		line-height: 2.2rem;
		color: $color-neutral;
		@extend .text-uppercase;
		@extend .strong;
		// @extend .text-shadow-subtle;
	}
	.social-media {
		z-index: 1;
		// position: absolute;
		// right: 0;
		// bottom: 0;
		// top: $gutter/2;
		// margin: $gutter/2 $gutter;
		//@extend .pull-right;
		// @extend .visible-md;
		a {			
			font-size: 2rem;
			margin: 0 $gutter/4;
			i {
				color: $color-transparent-white-7;
				&:hover {
					color: $color-real-white;
				}
			}
		}
	}
}

.container {
	max-width: $max-width;
	// max-width: 1200px;
	width: 100%;

	margin-left: auto;
	margin-right: auto;

	@include breakpoint_min($xxl) {
		max-width: $max-width-xxl;
	}
	@include breakpoint_min($xxxl) {
		max-width: $max-width-xxxl;
	}
}

.headline:not(.container) > *:not(.wide):not(.disable-container), .with-children-container > *:not(.wide), .welcome-home > div > * {
	@extend .container;
}

.headline-cover {
	position: relative !important;
	&:before {
		content: '';
		background-image: var(--image);
		background-size: cover;
		background-size: 600%;
		background-position: center;
		background-repeat: no-repeat;
		filter: blur(10px);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -$gutter*2.5; // @tabpane nav
		// bottom: -440px; // @tabpane nav + @recentusers
		z-index: -1;
		top: -$gutter*4;
		@include breakpoint_max($md) {
			top: -$gutter*4;
			bottom: -$gutter; // @tabpane nav
			filter: blur(40px);
		}
	}

	&:after {
		content: '';
		background: rgba(0, 0, 0, 0.7);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -$gutter*2.5; // @tabpane nav
		// bottom: -440px; // @tabpane nav + @recentusers
		z-index: -1;
		top: -$gutter*4;
		@include breakpoint_max($md) {
			background: rgba(0, 0, 0, 0.7);
			bottom: -$gutter*2.5; // @tabpane nav
			top: -$gutter*4;
		}
	}
}


// special cases of atention overlay (eg: loading screen, 404 errors, etc)
.attention-overlay {
	position: fixed;
	bottom: 0;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	color: $color-white;
	color: #fff;
	position: relative;
	min-height: 400px;
	height: 100vh;
	margin: auto;
	background: $color-background-base;
	overflow: hidden;
	//max-width: $xl;
	@extend .font-distinct;
	@include breakpoint_max($md) {
		height: calc(100vh - #{$gutter*3}); // was 6
	}
	> span {
		position: absolute;
		font-size: 3rem;
		line-height: 1em;
		left: 0;
		right: 0;
		top: 50%;
		padding: 0 $gutter/2;
		transform: translateY(-50%);
		@extend .text-center;
		i {
			font-size: 8rem;
		}
		p {
			&.loading {
				&:after {
					content: '...';
					position: absolute;
					padding-top: $gutter/2;
					@include animation(translateX, $time: infinite, $timing-function: linear);
				}
			}
			&:not(:first-of-type), &.not-that-big-doe {
				font-size: 1.2rem;
				line-height: 2em;
			}
		}
	}
	&.error {
		// background: lighten($color-error, 20%);
		background: #111;
		// @include breakpoint_min($lg) {
		// 	// background: radial-gradient(circle at center, #222 30%, lighten($color-overlay, 5%) 30% 32%, $color-overlay 32%);
		// 	background: radial-gradient(circle at center, #222 30%, lighten($color-overlay, 5%) 30% 31%,  $color-overlay 31%);
		// }
		span {
			@include breakpoint_max($sm) {
				top: 40%;
			}
		}
		i {
			// @include neon($color-error);
			@include animation(neon-error, $duration: 2s, $delay: 1.3s);
			position: relative;
			/**
			&::after {
				z-index: -1;
				content: "\f243";
				position: absolute;
				top: 0;
				left: 0;
				@include animation(opacity, $duration: 1s)
			}
			**/
			&::after {
				z-index: -1;
				content: "";
				position: absolute;
				top: 25%;
				bottom: 25%;
				left: 9%;
				width: 40%;
				background: white;
				@include animation(width-error, $time: 1, $duration: 1s, $delay: 0.5s, $fill-mode: forwards)
			}
		}
	}
	&.info {
		i {
			// @include neon(turquoise, $breakpoint_min: 0);
			@include neon($color-primary, $breakpoint_min: 0);

		}
	}
	+ footer {
		display: none;
	}
}

.welcome-home {
	@extend .font-distinct;
	@include breakpoint_min($lg) {
		 .adaptive-grid {
			padding: 0 $gutter*2;
		}
	}
	@include breakpoint_max($lg) {
		.padding-section {
			> .adaptive-grid {
					
				> div {
					// background: #fff;
					z-index: 1;
					flex: 0 0 100%;
					order: 2;
					&.position-relative {
						// z-index: 2;
						order: 1;
						overflow: hidden;
						min-height: 400px;
						margin-top: -$gutter*3;
						margin-bottom: $gutter*3;
						margin-left: -$gutter;
						margin-right: -$gutter;
						flex-basis: calc(100% + #{$gutter*2})
					}
					&.with-adaptive-border {
						&:after {
							content: '';
							position: absolute;
							left: -20%;
							right: -20%;
							bottom: 0;
							height: 1px;
							z-index: 3;
							background: $color-transparent-black-1;
						}
					}
				}
				// > div:not(.visible-md) + div {
				// 	padding-bottom: $gutter;
				// }
			}
		}
		.padding-section  {
			&.bg-light {
				> .adaptive-grid {
					> div {
						background: $color-light;
					}
				}
			}
		}
	}
	
	.time-explorer-phones {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -$gutter*3;
		overflow: hidden;
		// margin-left: -$gutter;
		// margin-right: -$gutter;
		// margin-bottom: -$gutter;
		@include breakpoint_min($lg) {
			left: -$gutter;
			right: -$gutter;
			overflow: inherit;
			// margin-left: $gutter*3;
			margin-right: $gutter*5;
		}
		img {
			width: 90px;
		}
	}
}

@include breakpoint_min($sm) {
	.pattern {
		position: inherit;
		// position: initial;
		// position: relative;
		// &:before {
		// 	// @4parallax
		// 	z-index: -1;
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 0;
		// 	//background: $color-transparent-black-3;
		// 	// thks to http://www.patternify.com/
		// 	// background: $color-transparent-black-7;
		// 	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHUlEQVQYV2NkYGAwZmBgOMsAAcaMUAacwiqAogUAUhUCaz2cAtIAAAAASUVORK5CYII=);
		// 	border-radius: $border-radius;
		// 	// pointer-events: none;
		// }
	}
}

/**
::-webkit-scrollbar {
    width: $gutter/2;
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: $color-neutral;
}
**/

// input {
//     &::placeholder {
//         color: $color-neutral;
//     }
// }

textarea:focus, input:focus {
    outline: none;
}

.delay {
	transition-delay: 0.3s;
}
.circle-action {
	z-index: 3;
	cursor: pointer;
	font-size: 1.2em;
	border-radius: 50%;
	padding: $gutter;
	background: $bg-color-neutral;
	@extend .text-success;
	@extend .box-shadow;
	@include transition-hover-scale();
	&.fixed {
		position: fixed;
		right: $gutter-big;
		bottom: $gutter-big
	}
	&.hide {
		opacity: 0;
		z-index: -1;
		transform: scale(0);
	}
}
// .parallax {
// 	// @4parallax
// 	// was $md
// 	@include breakpoint_min(1px) {
// 		position: fixed;
// 		overflow: hidden;
// 		z-index: 1;
// 		background: 100% 0% / cover; 
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 100%;
		
// 		will-change: transform; // creates a new paint layer
// 		transition: transform $transition-timing linear 0s, filter $transition-timing linear 0s;
// 		transition-delay: 0.7s;
	
// 		// background-image: url('https://images.unsplash.com/photo-1556691421-cf15fe27a0b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
// 		// was 0.7s. on active: 0.5s + 0.8s 
// 		// was $xxl\
// 			// @paralax disabled || not 
// 			// background-image: url($cover-path); // @road one
// 			// @include breakpoint_min($xxl) {
// 				background-image: linear-gradient(315deg, rgb(127, 90, 131) 0%, lighten($color-secondary, 10%) 74%); ; // @bgphotocolor @tempbgcolor
// 				@if variable-exists(parallax-bg) {
// 					background: $parallax-bg;
// 				}
// 			// }

// 			@if variable-exists(parallax-bg) {
// 				background: $parallax-bg;
// 			}
// 				background-image: repeating-linear-gradient(-45deg, transparent, rgba($color-secondary, 0.21) 80px),
// 				repeating-linear-gradient(45deg, transparent, rgba($color-secondary, 0.21) 80px);
// 			&.active {
// 			@include breakpoint_min($xl) {
// 				// disable cos this may look ugly if component is being destroyed.
// 				// transition: transform $transition-timing linear 0s, filter $transition-timing linear 0s;
// 				transform: scale(1.2);
// 				filter: blur(7px);
// 			}
// 		}
// 	}
// }

.loading-component {
	min-height: 250px;
	position: relative;
	&:after {
		content: 'Loading...';
		position: absolute;
		bottom: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// .mini-container {
// 	max-width: $md;
// }

.loading-overlay-fullscreen {
	z-index: 2;
	background: $bg-color-neutral;
	background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	> * {
		position: fixed;
		margin: auto;
		width: 200px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		@include animation(pulsate);
	}
}

.table-stripped {
	width: 100%;
	td {
		width: 50%;
		padding: $gutter/3;
	}
	tr:nth-child(odd) {
		background: $bg-color-neutral;
	}
}

.attention-overlay + .mowned-you-shall-like {
	display: none;
}

// .mowned-you-shall-like {
// 	// z-index: 2;
// 	// position: relative;
// 	p {
// 		@include breakpoint_max($sm) {
// 			font-size: 0.8rem;
// 			&.title {
// 				font-size: 2rem;
// 			}
// 		}
// 	}
// 	> div {
// 		@include breakpoint_min($md) {
// 			padding: $gutter*2 $gutter !important;
// 		}
// 		@extend .text-center;
// 		// @extend  .bg-transparent-white;
// 		// @extend  .border-top-transparent-white;
// 		// @extend  .border-bottom-transparent-white;
// 	}
// 	// p {
// 	// 	font-size: 1rem;
// 	// }
// }

.page-me {
	.headline {
		@include breakpoint_min($lg) {
			padding-bottom: $gutter*2 !important;
		}
		h1 {
			> span {
				max-width: 65%;
				vertical-align: middle;
				@extend .text-ellipsis;
			}
			@include breakpoint_min($xl) {
				padding-left: $gutter*2;
				> span {
					max-width: 100%;
				}
			}
		}
	}
	&.phone-count-0 + .mowned-you-shall-like {
		display: none;
		// max-height: 0;
		// overflow: hidden;
	}
}



// .state-new-phone {
// 	background: rgba(255, 255, 255, 0.9);
// }

// @todo improve tab navigate visuals
// *:focus {
// 	outline: 3px solid $color-transparent-white-1 !important;
// 	// outline-offset: 4px;
// 	background-image: linear-gradient(rgba($color-primary, 0.3), rgba($color-secondary, 0.3)) !important;
// }


// @temp
@include breakpoint_min($lg) {
	// body {
	// 	overflow-x: hidden;
	// }
	.app, .welcome-home {
		transition: all $transition-timing $transition-timing-function;
	}
	.app {
		transition-delay: $transition-timing;
	}
	header.menu-active:not(.sticky) {
		+ .app {
			transition-delay: $transition-timing/4;
		}
		+ .app, + .welcome-home {
			// transform: translateX(300px) rotateZ(10deg);
			transform: translateY(240px);
		}
	}
}

.dave {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 103;
}
.attention-overlay .dave {
	position: absolute;
}

.is-high-performance {
	.cover-container:after {
		background-color: rgba($color-background-base, 0.9);
		background-blend-mode: luminosity;
		filter: none;
		animation-delay: 0.1s;
	}
}
.cover-container {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: 25% 25%/cover;
		// background-position-x: 47%;
		background-position-x: 50%;
		@include breakpoint_min($lg) {
			background-position-x: 50%;
		}

		// Cover Photo by Jonah Pettrich on Unsplash
		background-image: url($cover-back-path);
		// background-image: url('https://images.unsplash.com/photo-1557690267-fad2f168bb95?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'); // super
		// background-image: url('https://images.unsplash.com/photo-1526222406293-5d82651335d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'); // noice
		// background-image: url('https://images.unsplash.com/photo-1526222483549-1542bfac7398?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2688&q=80'); // nice one too
		// background-image: url('https://images.unsplash.com/photo-1516445490264-c56dd5ca638f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80'); // super artistic 
		// background-image: url('https://images.unsplash.com/photo-1526045612212-70caf35c14df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'); // nice business phone
		// background-image: url('https://images.unsplash.com/photo-1505740494862-e7e49c099cf1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'); // flat htc phone
		// filter: blur(12px);
		transform: scale(1.3);
	
		transform-origin: bottom;
		transform-origin: top;
		background-position-y: bottom;
		// background-image: repeating-linear-gradient(-45deg, transparent, rgba($color-secondary, 0.21) 80px),
		// 		repeating-linear-gradient(45deg, transparent, rgba($color-secondary, 0.21) 80px);
		animation-fill-mode: forwards;
		filter: hue-rotate(191deg);
		animation-delay: 0s;
		@extend .animate-scale-in;
		@extend .anim;
	}
	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	background: radial-gradient(circle at top right, rgba(0, 0, 0, 0.2) 30%, transparent 30%), radial-gradient(circle at bottom right, rgba(0, 0, 0, 0.2) 50%, transparent 50%);
	// 	z-index:  1;
	// }

	h1 {
		position: absolute;
		z-index: 3;
		bottom: $gutter/2;
		left: $gutter;
		right: $gutter;
		// font-size: 1.5rem;
		font-size: 1.9rem;
		padding-right: $gutter*2;
		text-align: left;
		color: $color-real-white;
		color: $color-white;
		@extend .text-center;
		@extend .text-shadow-subtle;
		// @extend .anim;
		// @include breakpoint_min($md) {
		// 	&:before {
		// 		content: 'Welcome to';
		// 		display: block;
		// 		text-transform: uppercase;
		// 		font-size: 0.4em;
		// 		line-height: 2em;
		// 	}
		// }
		@include breakpoint_min($lg) {
			font-size: 2.8rem;
			font-weight: 100;
			left: $gutter*2;
			bottom: $gutter;
			margin-left: $gutter*2;
			//right: $gutter*2;
			// top: $gutter;
			max-width: 550px;
			line-height: 3.5rem;
			b {
				font-size: 3.5rem;
			}
		}
		b {
			display: block;
			@extend .strong;
		}
	}
	i {
		position: absolute;
		bottom: $gutter;
		right: $gutter;
		font-size: 3rem;
		z-index: 3;
		&:before {
			opacity: 0.3;
		}
		// @extend .text-center;
		// &::after {
		// 	// position: absolute;
		// 	content: 'Keep scrolling';
		// 	display: block;
		// 	position: relative;
		// 	font-size: 1rem;
		// 	bottom: 0;
		// 	padding-bottom: $gutter*2;
		// 	@extend .font-distinct;
		// 	@extend .text-uppercase;
		// 	@extend .text-nowrap;
		// 	right: 0;
		// }
	}
}

// *[tabindex="-1"]
.disable-focus {
	outline: none;
}

.cover {
	$browser-header: 56px;
	position: relative;
	z-index: 2;
	// height: 60vh;
	height: calc(100vh - #{$browser-header});
	@include breakpoint_min($lg) {
		height: 100vh;
	}
	> div {
		display: none;
	}
}

.navbar-secondary {
	position: -webkit-sticky;
	position: sticky;
	z-index: 111;
	background: rgba($color-overlay, 0.99);
	top: $gutter*2.7; // after navbar.
	@include breakpoint_min($lg) {
		top: $gutter*2.9; // after navbar.
	}
}